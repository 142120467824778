// src/components/DetailArea/PostDetail/PostDetailAttachments.jsx

import React from "react";
import AttachmentObject from "../../Utils/AttachmentObject";

function AttachmentsContainer({ post }) {
  const attachments = post?.attachments || [];

  if (!attachments.length) {
    return null;
  }

  return (
    <div className="attachments-container">
      {attachments.map((attachment, index) => (
        <AttachmentObject
          key={index}
          fileType={"attachment"}
          fileData={attachment}
          isInitiallyExpanded={true}
        />
      ))}
    </div>
  );
}

export default AttachmentsContainer;

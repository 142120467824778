// src/services/Users/currentUser.js

import axiosInstance from "../Utils/axiosInstance";

const toggleUserReaction = async (postContentId) => {
  try {
    const response = await axiosInstance.post(
      `/api/posts/toggle_reaction_by_post_content/`,
      {
        post_content_id: postContentId,
      },
    );
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

export { toggleUserReaction };

// src/services/Auth/passwordResetRequest.js

import axiosInstance from "../Utils/axiosInstance";

const passwordResetConfirm = async (uid, token, password1, password2) => {
  const password = password1;
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  // validate passwords are not blank
  if (password1 === "" || password2 === "") {
    warningMessage = "Please make sure both password fields are filled out.";
    return { successMessage, warningMessage, dangerMessage };
  }

  // validate passwords match
  if (password1 !== password2) {
    warningMessage = "Passwords must match.";
    return { successMessage, warningMessage, dangerMessage };
  }

  try {
    await axiosInstance.post(`/api/auth/password-reset/confirm/`, {
      uid: uid,
      token: token,
      password: password,
    });
    successMessage = "Password reset successful! Redirecting to login page...";
  } catch (error) {
    if (error.response) {
      if (error.response.data.password) {
        dangerMessage = error.response.data.password.join(" ");
      } else {
        dangerMessage = error.response.data.detail;
      }
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }

  return { successMessage, warningMessage, dangerMessage };
};

export { passwordResetConfirm };

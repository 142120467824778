// src/components/ReplyContainer/AttachmentsContainer.jsx

import React from "react";
import AttachmentObject from "../Utils/AttachmentObject";

function AttachmentsContainer({ post }) {
  const tracks = post?.tracks || [];
  const attachments = post?.attachments || [];

  if (!tracks.length && !attachments.length) {
    return null;
  }

  return (
    <div className="attachments-container">
      {tracks.map((track, index) => (
        <AttachmentObject key={index} fileType={"track"} fileData={track} />
      ))}
      {attachments.map((attachment, index) => (
        <AttachmentObject
          key={index}
          fileType={"attachment"}
          fileData={attachment}
        />
      ))}
    </div>
  );
}

export default AttachmentsContainer;

// src/stores/PostDetailStore.js

import { atom } from "nanostores";
import { getChildPosts } from "../services/Posts/getChildPosts";
import { getParentTree } from "../services/Posts/getParentTree";
import { getPostDetail } from "../services/Posts/getPostDetail";
import { updateProjectPosts } from "../services/Collectives/updateProjectPosts";

// store containing all the posts data entirely
export const allPostsStore = atom({});
// store containing the active post
export const activePostIdStore = atom(null);
// store to track if a parent post is being created
export const parentPostIsBeingCreatedStore = atom(false);
// store to track the parent post IDs as instructions for which child posts to render
export const renderChildPostIdsArrayStore = atom([]);
// store to set the posts that should be highlighted based on new
export const highlightedPostsArrayStore = atom([]);
// store to indicate that posts are being moved from the collecive to a project
export const projectPostsAreBeingEditedStore = atom(false);

export function clearPostStore() {
  allPostsStore.set({});
  activePostIdStore.set(null);
  parentPostIsBeingCreatedStore.set(false);
  renderChildPostIdsArrayStore.set([]);
  highlightedPostsArrayStore.set([]);
  projectPostsAreBeingEditedStore.set(false);
}

// Function to set post details directly by a single post
export function setPostDetails(post) {
  const allPosts = allPostsStore.get();
  // Check if the post already exists
  const existingPost = allPosts[post.id];

  // If the post already exists and is complete, do not update with partial data
  // This is used to prevent partial data from overwriting complete data
  // For example, on a page load, the data may be pulled twice and may overwrite the complete data with partial data
  if (existingPost && existingPost.is_complete_data && !post.is_complete_data) {
    // If the existing data is complete and the incoming data is not, do not update
    return;
  }

  // If the post already exists and has children, retain the existing children
  if (existingPost && existingPost.children) {
    post.children = existingPost.children;
  }

  // Update the store with either the new full details or the new incoming partial data
  allPostsStore.set({ ...allPosts, [post.id]: post });
}

// Function to set a default active post - used for when no active post is selected
export function setDefaultActivePost() {
  const currentPostDetails = allPostsStore.get();

  // Set active post by default based on the latest parent post that is not soft deleted
  let latestPost = null;
  for (let id in currentPostDetails) {
    const post = currentPostDetails[id];
    if (post.soft_deletion_date === null && post.parent === null) {
      if (
        latestPost === null ||
        new Date(post.created_at) > new Date(latestPost.created_at)
      ) {
        latestPost = post;
      }
    }
  }

  // Set the latest post as the active post
  if (latestPost !== null) {
    setActivePostId(latestPost.id);
  }
}

// Function to set active post
export function setActivePostId(postId) {
  // Clear a post creation if one is in progress
  cancelPostCreation();

  // Set the active post
  // Explicitely convert to a number as this will cause bugs if the incoming postId is a string
  activePostIdStore.set(Number(postId));

  // set the postID in the URL
  setPostInUrl(postId);
}

// Unset the active post - such as when creating a new post
export function clearActivePost() {
  activePostIdStore.set(null);
  removePostFromUrl();
}

// fetch the details for a single post
export async function fetchPostDetail(postId) {
  try {
    const post = await getPostDetail(postId);
    setPostDetails(post);
  } catch (error) {
    console.error(error);
  }
}

// fetch the tree details for a post
export async function fetchParentTree(postId) {
  try {
    const postTreeData = await getParentTree(postId);
    // Extract the IDs from the parent tree
    const postTreeIds = postTreeData.parent_tree;

    // Loop through the parent_tree array and set to render the child posts
    postTreeIds.forEach((id) => {
      setPostIdToRenderChildPostsArray(id);
    });

    // set the child post as a highlighted post
    setPostInHighlightedPostsArray(postId);
  } catch (error) {}
}

// Fetch child posts for a parent post and append them to the allPostsStore
export async function fetchChildPosts(postId) {
  try {
    const childPosts = await getChildPosts(postId);
    // Extract the IDs from the child posts
    const childPostIds = childPosts.map((post) => post.id);

    // Append the child posts to the allPostsStore
    childPosts.forEach((post) => {
      setPostDetails(post);
    });

    // Add the child post Ids to the parent post
    // Get the current state of all posts
    const allPosts = allPostsStore.get();
    // Update the parent post with the child post IDs
    allPosts[postId].children = childPostIds;
    // Set the updated state back to the store
    allPostsStore.set(allPosts);
  } catch (error) {}
}

export async function patchProjectPosts(projectId, postsToAdd, postsToRemove) {
  try {
    const projectPostData = await updateProjectPosts(
      projectId,
      postsToAdd,
      postsToRemove,
    );
    const updatedPosts = projectPostData;
    // Append the posts to the allPostsStore
    updatedPosts.forEach((post) => {
      setPostDetails(post);
    });
  } catch (error) {}
}

// function to handle creating a new parent post
export function createNewParentPost() {
  parentPostIsBeingCreatedStore.set(true);
  clearActivePost();
}

// function to cancel creating a new post
export function cancelPostCreation() {
  parentPostIsBeingCreatedStore.set(false);
}

// function to handle editing project posts
export function editProjectPosts() {
  projectPostsAreBeingEditedStore.set(true);
  clearActivePost();
}

export function cancelEditProjectPosts() {
  projectPostsAreBeingEditedStore.set(false);
}

export function getUrlParams() {
  // Parsing the URL to find the collectiveId
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const urlCollectiveId = pathSegments[pathSegments.indexOf("collective") + 1];

  // Use URLSearchParams to get query parameters
  const params = new URLSearchParams(url.search);
  const urlProjectId = params.get("projectId");
  const urlPostId = params.get("postId");
  const urlChildPostId = params.get("childPostId");

  return { urlCollectiveId, urlProjectId, urlPostId, urlChildPostId };
}

export function setUrl(collectiveId, projectId, postId, childPostId) {
  const collectiveUrlPart = collectiveId ? `/collective/${collectiveId}?` : "";
  const projectUrlPart = projectId ? `&projectId=${projectId}` : "";
  const postUrlPart = postId ? `&postId=${postId}` : "";
  const childPostUrlPart = childPostId ? `&childPostId${childPostId}` : "";

  const newUrl = `/studio${collectiveUrlPart}${projectUrlPart}${postUrlPart}${childPostUrlPart}`;

  // Use history.pushState() to change the URL without refreshing the page
  window.history.pushState({ path: newUrl }, "", newUrl);
}

export function setPostInUrl(postId) {
  const { urlCollectiveId, urlProjectId } = getUrlParams();
  setUrl(urlCollectiveId, urlProjectId, postId);
}

export function removePostFromUrl() {
  const { urlCollectiveId, urlProjectId } = getUrlParams();
  setUrl(urlCollectiveId, urlProjectId);
}

export function setPostInHighlightedPostsArray(postId) {
  const currentIds = highlightedPostsArrayStore.get();
  const numericPostId = Number(postId); // Explicitely convert to a number
  // Ensure the postId is not already included to avoid duplicates
  if (!currentIds.includes(numericPostId)) {
    highlightedPostsArrayStore.set([...currentIds, numericPostId]);
  }
}

// add the post ID to the array of post IDs to render child posts
export function setPostIdToRenderChildPostsArray(postId) {
  const currentIds = renderChildPostIdsArrayStore.get();
  const numericPostId = Number(postId); // Explicitely convert to a number
  // Ensure the postId is not already included to avoid duplicates
  if (!currentIds.includes(numericPostId)) {
    renderChildPostIdsArrayStore.set([...currentIds, numericPostId]);
  }
}

// remove the post ID from the array of post IDs to render child posts
export function removePostIdFromRenderChildPostsArray(postId) {
  const currentIds = renderChildPostIdsArrayStore.get();
  const updatedIds = currentIds.filter((id) => id !== postId);
  renderChildPostIdsArrayStore.set(updatedIds);
}

// clear all post IDs from the array of post IDs to render child posts
export function clearAllPostIdsFromRenderChildPostsArray() {
  renderChildPostIdsArrayStore.set([]);
}

// function to mark a post as read
export function markPostAsRead(postId) {
  const currentIds = highlightedPostsArrayStore.get();
  const updatedIds = currentIds.filter((id) => id !== postId);
  highlightedPostsArrayStore.set(updatedIds);
}

import React from "react";

const CreateNewProjectButton = () => {
  return (
    <div className="btn-circle-md btn-create-project">
      <div>
        <span>
          <i className="bi bi-folder-plus"></i>
        </span>
      </div>
    </div>
  );
};

export default CreateNewProjectButton;

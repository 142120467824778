import React from "react";
import Select from "react-select";

const SelectListField = ({
  fieldName,
  initialValue,
  setValue,
  dropdownList,
  listValue,
  listDisplay,
  isLoading,
  placeholder,
}) => {
  // Convert dropdownList to format required by react-select
  // use listValue and listDisplay to determine which fields to use
  const options = dropdownList.map((item) => ({
    value: item[listValue],
    label: item[listDisplay],
  }));

  // Handle change event
  const handleChange = (selectedOption) => {
    setValue(selectedOption.value);
  };

  // Convert initialValue to format required by react-select
  const value = options.find((option) => option.value === initialValue);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      textAlign: "left",
      background: "var(--background-color)",
      borderRadius: ".7rem",
      padding: ".3rem",
      color: "var(--text-color)",
      borderColor: state.isFocused
        ? "var(--border-color-focused)"
        : "var(--border-color)",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused
          ? "var(--border-color-focused)"
          : "var(--border-color)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--text-color)",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      background: "var(--button-background)",
      color: "var(--text-color)",
      borderRadius: ".5rem",
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "var(--button-background-hover)"
        : "transparent",
      color: "var(--text-color)",
      padding: ".4rem",
      paddingLeft: "1rem",
      cursor: "pointer",
      fontSize: state.isSelected ? "1rem" : ".9rem",
      fontWeight: state.isSelected ? "600" : "300",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "100%",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "100%",
    }),
  };

  return (
    <div>
      {fieldName && (
        <div className="new-post-form-input-fieldname text-prevent-selecting">
          {fieldName}
        </div>
      )}
      <Select
        value={value}
        onChange={handleChange}
        options={options}
        isDisabled={isLoading}
        placeholder={placeholder}
        styles={customStyles}
      />
    </div>
  );
};

export default SelectListField;

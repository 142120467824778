const LoadingIndicatorBars = () => {
  const spinnerStyle = {
    fill: "currentColor",
    width: "100%",
    height: "100%",
  };

  const spinnerKeyframes = `
    @keyframes spinner_B8Vq {
      0%, 66.66% {
        animation-timing-function: cubic-bezier(0.36, 0.61, 0.3, 0.98);
        y: 6px;
        height: 12px;
      }
      33.33% {
        animation-timing-function: cubic-bezier(0.36, 0.61, 0.3, 0.98);
        y: 1px;
        height: 22px;
      }
    }
  `;

  const rectClassName = (delay) => ({
    animation: `spinner_B8Vq 1.1s linear infinite ${delay}s`,
  });

  return (
    <div className="loading-indicator-container">
      <style>{spinnerKeyframes}</style>
      <svg viewBox="0 0 24 24" style={spinnerStyle}>
        <rect
          style={rectClassName(-0.9)}
          x="1"
          y="6"
          width="2.8"
          height="12"
        ></rect>
        <rect
          style={rectClassName(-0.8)}
          x="5.8"
          y="6"
          width="2.8"
          height="12"
        ></rect>
        <rect
          style={rectClassName(-0.7)}
          x="10.6"
          y="6"
          width="2.8"
          height="12"
        ></rect>
        <rect
          style={rectClassName(-0.6)}
          x="15.4"
          y="6"
          width="2.8"
          height="12"
        ></rect>
        <rect
          style={rectClassName(-0.5)}
          x="20.2"
          y="6"
          width="2.8"
          height="12"
        ></rect>
      </svg>
    </div>
  );
};

export default LoadingIndicatorBars;

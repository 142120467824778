// src/stores/authStore.js

import { atom } from "nanostores";
import { refreshToken } from "../services/Auth/refreshToken";

// set initial value to true as refresh token logic will check and update the store
export const isAuthenticatedStore = atom(true);

export async function confirmAuthentication() {
  await refreshToken();
}

export function setIsAuthenticated() {
  isAuthenticatedStore.set(true);
}

export function setIsNotAuthenticated() {
  isAuthenticatedStore.set(false);
}

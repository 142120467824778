// src/components/Buttons/CloseButton.jsx

import React from "react";

const CloseButton = () => {
  const iconContainerStyle = {
    display: "flex",
    position: "absolute",
    top: "0",
    right: "0",
    margin: ".5rem",
  };

  const iconStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    color: "var(--text-muted)",
  };

  return (
    <div className="btn-circle-lg" style={iconContainerStyle}>
      <i className="bi bi-x-lg" style={iconStyle}></i>
    </div>
  );
};

export default CloseButton;

//src/components/Forms/InviteNewUserForm.jsx

import React, { useState, useEffect } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import { isModalLoadingStore } from "../../stores/modalStore.js";
import { SuccessAlert, WarningAlert, DangerAlert } from "../Utils/Alerts.jsx";
import { inviteNewUser } from "../../services/UserInvite/inviteNewUser.js";
import { listCollectives } from "../../services/Collectives/listCollectives.js";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  projectIdStore,
  fetchCollectiveDetails,
} from "../../stores/collectiveDetailStore.js";
import { userIsAdminOwnerOrMember } from "../Utils/permissionsUtils.js";

const InviteNewUserForm = () => {
  const activeCollectiveId = useStore(collectiveIdStore);
  const activeProjectId = useStore(projectIdStore);

  // loading states for managing the initial users and the submit button
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  // states for managing the messages
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");
  const [inviteUrl, setInviteUrl] = useState("");

  // states for managing the form inputs
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");

  // states for managing other values
  const [collectives, setCollectives] = useState([]);
  const [selectedCollectives, setSelectedCollectives] = useState([]);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchLoading(true);
      const response = await listCollectives();
      if (response && Array.isArray(response)) {
        const filteredResponse = response.filter((collective) =>
          userIsAdminOwnerOrMember(collective.user_role),
        );
        setCollectives(filteredResponse);
        const activeCollective = filteredResponse.find(
          (collective) => collective.id === activeCollectiveId,
        );

        // if the active collective is found, make it selected
        if (activeCollective) {
          setSelectedCollectives((prevCollectives) => [
            ...prevCollectives,
            activeCollective,
          ]);
        }
      }
      setIsFetchLoading(false);
    };

    fetchData();
  }, [activeCollectiveId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Initial loading state on submit
    isModalLoadingStore.set(true);

    // Set the selected collectives to an array of collective ids
    const collectiveIds = selectedCollectives.map(
      (collective) => collective.id,
    );

    // Call API and await messages
    const response = await inviteNewUser(
      emailValue,
      messageValue,
      collectiveIds,
    );
    setSuccessMessage(response.successMessage);
    setWarningMessage(response.warningMessage);
    setDangerMessage(response.dangerMessage);
    setInviteUrl(response.inviteUrl);

    if (response.dangerMessage || response.warningMessage !== "") {
      setIsLoading(false);
      isModalLoadingStore.set(false);
    }

    if (response.successMessage) {
      setIsLoading(false);
      isModalLoadingStore.set(false);
      fetchCollectiveDetails(activeCollectiveId, activeProjectId);
    }
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessageValue(event.target.value);
  };

  // handle copying the invite link
  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(inviteUrl).then(() => {
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    });
  };

  // handle the selection of users in the search results
  const handleCollectiveSelection = (collective, e) => {
    if (e.target.checked) {
      setSelectedCollectives((prevCollectives) => [
        ...prevCollectives,
        collective,
      ]);
    } else {
      setSelectedCollectives((prevCollectives) =>
        prevCollectives.filter((u) => u.id !== collective.id),
      );
    }
  };

  if (isFetchLoading) {
    return (
      <div style={{ height: "3rem", color: "var(--primary-color)" }}>
        <LoadingIndicatorBars />
      </div>
    );
  }

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        {/* On success, hide the form */}
        {!successMessage && (
          <>
            {" "}
            <div>
              <input
                type="email"
                name="email"
                className="form-text-input"
                placeholder="Recipient's email address"
                autoComplete="email"
                disabled={isLoading}
                value={emailValue}
                onChange={handleEmailChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="message"
                className="form-text-input"
                placeholder="Provide a custom message (optional)"
                disabled={isLoading}
                value={messageValue}
                onChange={handleMessageChange}
              />
            </div>
            <div>
              {collectives.length > 0 && (
                <div className="form-search-container form-search-results">
                  <p className="text-placeholder">
                    Add new user to a Collective
                  </p>
                  {collectives.map((collective) => (
                    <div
                      key={collective.id}
                      className="form-search-result-item"
                    >
                      <input
                        type="checkbox"
                        checked={selectedCollectives.some(
                          (selectedCollective) =>
                            selectedCollective.id === collective.id,
                        )}
                        onChange={(e) =>
                          handleCollectiveSelection(collective, e)
                        }
                      />
                      <h4>{collective.title}</h4>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              {isLoading ? (
                <div style={{ height: "3rem", color: "var(--primary-color)" }}>
                  <LoadingIndicatorBars />
                </div>
              ) : (
                <button
                  style={{ width: "100%" }}
                  className="btn-outline"
                  type="submit"
                >
                  <h3>Invite User</h3>
                </button>
              )}
            </div>
          </>
        )}
        <div>
          <SuccessAlert message={successMessage} />
          <WarningAlert message={warningMessage} />
          <DangerAlert message={dangerMessage} />
        </div>
        {successMessage && (
          <>
            <div>
              <p>
                Feel free to copy the link below if you'd like to share it
                through another method.
              </p>
            </div>
            <button
              style={{ width: "100%" }}
              className={`${isLinkCopied ? "btn-outline" : "btn-black"}`}
              type="button"
              onClick={handleCopyInviteLink}
            >
              <h3>{isLinkCopied ? "Link Copied" : "Copy Invite Link"}</h3>
            </button>
            <div>
              <p className="md text-muted">{inviteUrl}</p>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default InviteNewUserForm;

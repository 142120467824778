// src/services/Collectives/listCollectiveMembers.js

import axiosInstance from "../Utils/axiosInstance";

const listCollectiveMembers = async (collectiveId) => {
  // Define the search query parts
  const url = `/api/collectives/${collectiveId}/list_members/`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    // silently catch the error
  }
};

export { listCollectiveMembers };

// src/components/SideBar/SideBarPostsList.jsx

import React, { useState } from "react";
import SideBarPostCard from "./SideBarPostCard";
import SideBarNewPostCard from "./SideBarNewPostCard";
import ProjectImage from "../../Utils/ProjectImage.jsx";
import { Accordion } from "../../Utils/CustomAccordion";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  projectIdStore,
  projectTitleStore,
  projectImageStore,
} from "../../../stores/collectiveDetailStore";
import { clearActivePost } from "../../../stores/postDetailStore";
import { allPostsStore } from "../../../stores/postDetailStore";

function SidebarPostsList() {
  // convert the post store to an array so they can be mapped and rendered
  const posts = Object.values(useStore(allPostsStore));
  // get the active data from the store
  const activeCollectiveId = useStore(collectiveIdStore);
  const activeProjectId = useStore(projectIdStore);
  const activeProjectTitle = useStore(projectTitleStore);
  const activeProjectImage = useStore(projectImageStore);
  // filter the posts to only show the collective posts
  const collectivePosts = posts.filter(
    (post) => post.collective === activeCollectiveId,
  );
  const collectiveParentPosts = collectivePosts.filter((post) => !post.parent);
  const projectParentPosts = collectiveParentPosts.filter(
    (post) =>
      post.related_projects && post.related_projects.includes(activeProjectId),
  );

  // STATE VARIABLES
  const [isCollectivePostsOpen, setIsCollectivePostsOpen] = useState(
    activeProjectId ? false : true,
  );
  const [
    isAddPostsFromCollectiveButtonVisible,
    setIsAddPostsFromCollectiveButtonVisible,
  ] = useState(projectParentPosts.length === 0);

  // useEffect to set collective posts to open if

  const handleAddPostsFromCollective = () => {
    setIsAddPostsFromCollectiveButtonVisible(false);
    setIsCollectivePostsOpen(true);
  };

  const handleProjectClick = () => {
    clearActivePost();
  };

  // component for the active project header
  const ActiveProjectHeader = () => {
    return (
      <div className="sidebar-project-title" onClick={handleProjectClick}>
        <ProjectImage
          title={activeProjectTitle}
          image={activeProjectImage}
          size={"1.8rem"}
        />
        <span className="text-overflow-trim short">{activeProjectTitle}</span>
      </div>
    );
  };

  return (
    <>
      {activeProjectId && (
        <Accordion
          header={<ActiveProjectHeader />}
          isOpen={true}
          isDisabled={false}
        >
          <div className="sidebar-post-card-list">
            <div style={{ marginLeft: ".5rem" }}>
              {projectParentPosts
                .sort((a, b) => b.id - a.id)
                .map((post) => (
                  <SideBarPostCard key={post.id} postId={post.id} />
                ))}
            </div>
            {isAddPostsFromCollectiveButtonVisible && (
              <div
                style={{
                  display: "flex",
                  padding: ".5rem",
                  justifyContent: "center",
                }}
              >
                <div
                  className="btn-badge primary"
                  onClick={handleAddPostsFromCollective}
                >
                  Add Posts from Collective
                </div>
              </div>
            )}
          </div>
        </Accordion>
      )}
      <Accordion
        header="Collective Posts"
        isOpen={isCollectivePostsOpen}
        isDisabled={false}
      >
        <div className="sidebar-post-card-list">
          <>
            <SideBarNewPostCard />
          </>
          <>
            {collectiveParentPosts
              .sort((a, b) => b.id - a.id)
              .map((post) => (
                <SideBarPostCard
                  key={post.id}
                  postId={post.id}
                  showAddToProjectButton={activeProjectId ? true : false}
                />
              ))}
          </>
        </div>
      </Accordion>
    </>
  );
}

export default SidebarPostsList;

// src/components/Buttons/PlayButton.jsx

import React from "react";

const EditButton = ({ isEditable, onClick }) => {
  const canEdit = isEditable || false;

  const handleEditClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const iconContainerStyle = {
    display: "flex",
    position: "absolute",
    top: "0",
    right: "0",
  };

  const iconStyle = {
    padding: ".7rem",
  };

  if (!canEdit) {
    return null;
  }

  return (
    <div style={iconContainerStyle} onClick={handleEditClick}>
      <div className="btn-icon sm" style={iconStyle}>
        <i className="bi bi-pencil"></i>
      </div>
    </div>
  );
};

export default EditButton;

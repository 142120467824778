import React from "react";

const PublicFooter = () => {
  return (
    <footer>
      <p className="sm text-muted">
        © 2023-2024 Musicians Collective Studio. All Rights Reserved.
      </p>
    </footer>
  );
};

export default PublicFooter;

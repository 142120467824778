// src/components/Utils/ProjectImage.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import TinyDropdownIcon from "../Icons/TinyDropdownIcon";

function ProjectImage({
  collectiveId,
  projectId,
  title,
  image,
  showDropdownIcon,
  size,
}) {
  const navigate = useNavigate();
  // set a default size for the image if one is not provided
  const imageSize = size || "3rem";

  // get the initials of the project title to display as a placeholder image
  const projectInitials = title
    ? title
        .split(" ")
        .slice(0, 3)
        .map((word) => word[0])
        .join("")
        .toUpperCase()
    : "";

  const projectIconStyle = {
    width: imageSize,
    height: imageSize,
  };

  const projectInitialsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: `calc(${imageSize} * 0.35)`, // scales the size of the text to fit appropriately within the image
    fontWeight: "400",
    color: "var(--text-muted)",
  };

  // if the image is a file, convert it to a URL
  if (image instanceof File) {
    image = URL.createObjectURL(image);
  }

  // only navigate to project detail if the dropdown icon is not shown
  // this is to prevent navigating to project detail when the dropdown icon is clicked
  const handleProjectClick = () => {
    if (!showDropdownIcon && projectId && collectiveId) {
      navigate(`/studio/collective/${collectiveId}?projectId=${projectId}`);
    }
  };

  return (
    <div
      className="sidebar-collective-icon"
      style={projectIconStyle}
      onClick={handleProjectClick}
    >
      {image ? (
        <img src={image} alt={`Collective: ${title}`} />
      ) : (
        <>
          {projectInitials ? (
            <span
              className="text-prevent-selecting"
              style={projectInitialsStyle}
            >
              {projectInitials}
            </span>
          ) : (
            <span
              className="text-prevent-selecting"
              style={projectInitialsStyle}
            >
              <i className="bi bi-folder-plus"></i>
            </span>
          )}
        </>
      )}
      {showDropdownIcon && <TinyDropdownIcon />}
    </div>
  );
}

export default ProjectImage;

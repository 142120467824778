//src/index.js

import React from "react";
import ReactDOM from "react-dom/client"; // Updated import
import App from "./App";

// Styles
import "./styles/accordion.css";
import "./styles/alerts.css";
import "./styles/animations.css";
import "./styles/audio-player.css";
import "./styles/buttons.css";
import "./styles/custom-card.css";
import "./styles/detail-area.css";
import "./styles/dividers.css";
import "./styles/dropdown.css";
import "./styles/footer.css";
import "./styles/forms.css";
import "./styles/global.css";
import "./styles/headings.css";
import "./styles/horizontal-slider.css";
import "./styles/icons.css";
import "./styles/images.css";
import "./styles/loading.css";
import "./styles/modal.css";
import "./styles/navbar.css";
import "./styles/notifications-page.css";
import "./styles/paragraphs.css";
import "./styles/reply-container.css";
import "./styles/root.css";
import "./styles/scrollbar.css";
import "./styles/sidebar.css";
import "./styles/table.css";
import "./styles/text-formatting.css";
import "./styles/tooltip.css";
import "./styles/track-details.css";
import "./styles/waveform-visualizer.css";

const root = ReactDOM.createRoot(document.getElementById("root")); // Use createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// src/services/Posts/getPostDetail.js

import axiosInstance from "../Utils/axiosInstance";

const getPostDetail = async (postId) => {
  try {
    const response = await axiosInstance.get(`/api/posts/${postId}/retrieve`);
    return response.data;
  } catch (error) {}
};

export { getPostDetail };

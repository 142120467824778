// src/services/Auth/LogIn.jsx

import axios from "axios";
import { setIsAuthenticated } from "../../stores/authStore.js";

export const handleLogIn = async (e) => {
  e.preventDefault();

  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  const form = e.target;
  const formData = new FormData(form);
  const json = Object.fromEntries(formData.entries());

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Validation form data is not blank
  for (let key in json) {
    if (json[key] === "") {
      warningMessage = "Please enter both username and password.";
      return { successMessage, warningMessage, dangerMessage };
    }
  }

  // API
  try {
    const response = await axios.post(BACKEND_URL + "/api/auth/token/", json, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    localStorage.setItem("accessToken", response.data.access);
    localStorage.setItem("refreshToken", response.data.refresh);
    setIsAuthenticated();
    successMessage = "Login successful! Redirecting to MCS...";
  } catch (err) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = err.response.data.detail;
    } else if (err.request) {
      // The request was made but no response was received
      dangerMessage =
        "Login failed. Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Login failed due to an unexpected error.";
    }
  }

  return { successMessage, warningMessage, dangerMessage };
};

// src/components/DetailArea/DetailArea.jsx

import React from "react";
import CollectiveDetail from "./CollectiveDetail/CollectiveDetailView";
import ProjectDetail from "./ProjectDetail/ProjectDetailView";
import PostDetail from "./PostDetail/PostDetailView";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  projectIdStore,
  collectiveLoadingStore,
  collectiveErrorStore,
} from "../../stores/collectiveDetailStore";
import { activePostIdStore } from "../../stores/postDetailStore";
import PostDetailLoading from "./PostDetail/PostDetailLoading";

function DetailArea() {
  const activeCollectiveId = useStore(collectiveIdStore);
  const activeProjectId = useStore(projectIdStore);
  const activePostId = useStore(activePostIdStore);
  const collectiveDataIsLoading = useStore(collectiveLoadingStore);
  const collectiveDataHasErrored = useStore(collectiveErrorStore);

  // if collective data is loading or errored, render the PostDetailLoading component
  if (collectiveDataIsLoading || collectiveDataHasErrored) {
    return <PostDetailLoading />;
  }

  // if there is an active project, render the PostDetail component
  if (activePostId) {
    return <PostDetail />;
  }

  // otherwise, if there is an active project, render the ProjectDetail component
  if (activeProjectId) {
    return <ProjectDetail />;
  }

  // otherwise, if there is an active collective, render the CollectiveDetail component
  if (activeCollectiveId) {
    return <CollectiveDetail />;
  }
}

export default DetailArea;

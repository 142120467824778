import React from "react";
import { useStore } from "@nanostores/react";
import { sidebarIsMobileStore, toggleSidebar } from "../../stores/sidebarStore";

const SideBarButton = () => {
  const isSidebarMobile = useStore(sidebarIsMobileStore);

  // determine if the current page is the notifications page
  const isCollectivesPage = window.location.pathname.includes("collective");

  // only show the sidebar if it is mobile and on the collective page
  const showSideBarButton = isSidebarMobile && isCollectivesPage;

  const handleSidebarToggle = () => {
    toggleSidebar();
  };

  if (showSideBarButton) {
    return (
      <div className="btn-sidebar-toggle" onClick={handleSidebarToggle}>
        <i className="bi bi-list"></i>
      </div>
    );
  } else {
    return null;
  }
};

export default SideBarButton;

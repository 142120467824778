//src/components/Forms/CreateCollectiveForm.jsx

import React, { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import TextInputField from "./FormFields/TextInputField.jsx";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore.js";
import { createCollective } from "../../services/Collectives/createCollective.js";
import { editCollective } from "../../services/Collectives/editCollective.js";
import ProjectImage from "../Utils/ProjectImage.jsx";
import EditableImageContainer from "../Utils/EditableImageContainer.jsx";
import { WarningAlert, DangerAlert } from "../Utils/Alerts.jsx";
import { editCollectiveModalOpenStore } from "../../stores/modalStore.js";
import {
  collectiveIdStore,
  collectiveTitleStore,
  collectiveImageStore,
  collectiveDescriptionStore,
} from "../../stores/collectiveDetailStore.js";
import { useCollectiveNavigation } from "../../hooks/navigationHooks.js";

const CreateCollectiveForm = () => {
  const navigateToCollective = useCollectiveNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // states for managing the messages
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");

  // determine if the form is in edit mode
  useEffect(() => {
    const isEditModalOpen = editCollectiveModalOpenStore.get();
    setIsEditing(isEditModalOpen);
    if (isEditModalOpen) {
      setTitle(collectiveTitleStore.get());
      setImage(collectiveImageStore.get());
      setDescription(collectiveDescriptionStore.get());
    }
  }, []);

  // determine if the post button should be disabled
  useEffect(() => {
    if (title && description) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [title, description]);

  // handle the submit button
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);
    setWarningMessage("");
    setDangerMessage("");

    const handleSuccess = (collectiveId) => {
      closeModals();
      setIsLoading(false);
      navigateToCollective(collectiveId);
    };

    const handleFailure = (response) => {
      setWarningMessage(response.warningMessage);
      setDangerMessage(response.dangerMessage);
      isModalLoadingStore.set(false);
      setIsLoading(false);
    };

    // if the form is not editing a collective, create a new one
    if (!isEditing) {
      const response = await createCollective(title, description, image);

      if (response.successMessage) {
        const newCollectiveId = response.newCollectiveId;
        handleSuccess(newCollectiveId);
      } else {
        handleFailure(response);
      }
      // otherwise, edit the existing collective
    } else {
      const activeCollectiveId = collectiveIdStore.get();
      const response = await editCollective(
        activeCollectiveId,
        title,
        description,
        image,
      );

      if (response.successMessage) {
        handleSuccess(activeCollectiveId);
      } else {
        handleFailure(response);
      }
    }
  };

  const SubmitButton = () => {
    return (
      <>
        {isLoading ? (
          <div style={{ height: "3rem", color: "var(--primary-color)" }}>
            <LoadingIndicatorBars />
          </div>
        ) : (
          <button
            style={{ width: "100%" }}
            className="btn-primary"
            type="submit"
            disabled={isSubmitButtonDisabled || isLoading}
          >
            <h3>{isEditing ? "Save Changes" : "Create Collective"}</h3>
          </button>
        )}
      </>
    );
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <EditableImageContainer image={image} setImage={setImage}>
            <ProjectImage title={title} image={image} size={"10rem"} />
          </EditableImageContainer>
        </div>
        {title ? (
          <h1 className="text-prevent-selecting text-break text-wrap-2">
            {title}
          </h1>
        ) : (
          <h1 className="text-placeholder text-prevent-selecting">
            Collective Title
          </h1>
        )}
        <TextInputField
          initialValue={title}
          setValue={setTitle}
          isLoading={isLoading}
          placeholder={"What is the title of this Collective, Band, or Artist?"}
        />
        <TextInputField
          initialValue={description}
          setValue={setDescription}
          isLoading={isLoading}
          placeholder={
            "How will this Collective be used? What makes this Collective, Band, or Artist unique?"
          }
        />
        <div>
          <SubmitButton />
        </div>
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default CreateCollectiveForm;

//src/components/Forms/ReplyForm.jsx

import React, { useState, useEffect } from "react";
import ProfileImageContainer from "../ReplyContainer/ProfileImageContainer.jsx";
import LoadingIndicatorCircle from "../Utils/LoadingIndicatorCircle";
import { useStore } from "@nanostores/react";
import { currentUserStore } from "../../stores/currentUserStore";
import { fetchChildPosts } from "../../stores/postDetailStore";
import { addPost } from "../../services/Posts/addPost";
import { updateTrackCurrentTime } from "../../stores/activeTrackStore.js";
import { formatAudioTimestamp } from "../Utils/formatTime.js";
import { useDropzone } from "react-dropzone";
import { WarningAlert, DangerAlert } from "../Utils/Alerts.jsx";
import RichTextEditor from "./FormFields/RichTextEditor.jsx";

const ReplyForm = ({
  postId,
  collectiveId,
  track,
  trackTimestamp,
  relatedPostContentId,
  onPostSubmitted,
}) => {
  const currentUser = useStore(currentUserStore);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [richTextJson, setRichTextJson] = useState(null);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [mentionedPostIds, setMentionedPostIds] = useState([]);
  const [comment, setComment] = useState("");
  const parentPostId = postId;
  const title = undefined;
  const [files, setFiles] = useState([]);
  // set state so value can be cleared after post is submitted
  const [currentTrackTimestamp, setCurrentTrackTimestamp] =
    useState(trackTimestamp);

  // states for managing the messages
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");

  // use effect to determine if the send button should be disabled
  useEffect(() => {
    if (comment) {
      setIsSendButtonDisabled(false);
    } else {
      setIsSendButtonDisabled(true);
    }
  }, [comment]);

  // drag and drop file functionality setup
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (uploadedFiles) => {
      let duplicateFound = false; // Flag to track if any duplicate files are detected

      // Filter out duplicates and update the flag if any duplicates are found
      const newFiles = uploadedFiles.filter((newFile) => {
        const isDuplicate = files.some((file) => file.name === newFile.name);
        if (isDuplicate) {
          duplicateFound = true; // Mark that a duplicate was found
          return false; // Exclude this file from being added
        }
        return true;
      });

      if (duplicateFound) {
        // If a duplicate file was detected, set a temporary message
        setWarningMessage("This file has already been added to this post.");
        setTimeout(() => setWarningMessage(null), 2000); // Clear the message after 3 seconds
      }

      // Only update the state with new, non-duplicate files
      setFiles((currentFiles) => [...currentFiles, ...newFiles]);
    },
    noClick: true, // Prevents the dropzone from opening the file dialog when clicked
  });

  const clearForm = () => {
    setRichTextJson(null);
    setMentionedUserIds([]);
    setMentionedPostIds([]);
    setComment("");
    setFiles([]);
    setCurrentTrackTimestamp("");
    setWarningMessage("");
    setDangerMessage("");
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const response = await addPost(
      parentPostId,
      collectiveId,
      title,
      comment,
      files,
      richTextJson,
      mentionedUserIds,
      mentionedPostIds,
      currentTrackTimestamp,
      relatedPostContentId,
    );

    if (response.successMessage) {
      await fetchChildPosts(parentPostId);
      clearForm();
      setIsLoading(false);
      // check if the onPostSubmitted callback is defined in the parent component to notify the parent the post has been submitted
      if (onPostSubmitted) {
        onPostSubmitted();
      }
    } else {
      // Handle error messages
      setWarningMessage(response.warningMessage);
      setDangerMessage(response.dangerMessage);
      setIsLoading(false);
    }
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleAddFiles = (e) => {
    e.stopPropagation(); // Prevent the dropzone from being triggered
    open(); // open the file dialog
  };

  // timestamp click functionality
  const handlePlay = () => {
    updateTrackCurrentTime(track, currentTrackTimestamp);
  };

  return (
    <div className={`reply-form-container ${isLoading ? "loading-field" : ""}`}>
      <ProfileImageContainer user={currentUser} />
      <form
        {...getRootProps()}
        className={`reply-form ${isDragActive ? "drag-active" : ""}`}
        onSubmit={handlePostSubmit}
      >
        <input {...getInputProps()} style={{ display: "none" }} />
        <div className="reply-form-contents">
          <div className="reply-form-text-container">
            <RichTextEditor
              initialJson={richTextJson}
              initialText={comment}
              setJson={setRichTextJson}
              setText={setComment}
              setMentionedUserIds={setMentionedUserIds}
              setMentionedPostIds={setMentionedPostIds}
              placeholderValue={"Reply to post here"}
            />
            {currentTrackTimestamp && (
              <p className="sm text-muted" style={{ marginLeft: ".3rem" }}>
                <span>Commenting at </span>
                <span className="link" onClick={handlePlay}>
                  {formatAudioTimestamp(currentTrackTimestamp)}
                </span>
                <span> in: </span>
                <span>{track?.audio_file_name}</span>
              </p>
            )}
            {files.length > 0 && (
              <div className="attachments-container">
                {files.map((file, index) => {
                  const fileNameParts = file.name.split(".");
                  const extension = fileNameParts.pop();
                  const fileNameWithoutExtension = fileNameParts.join(".");

                  return (
                    <div key={index} className="attachment-object preview">
                      <p className="sm text-overflow-trim short">
                        {fileNameWithoutExtension +
                          (extension ? "." + extension : "")}
                      </p>
                      <i
                        onClick={() => handleFileRemove(file.name)}
                        className="bi bi-trash-fill attachment-remove"
                      ></i>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="reply-form-buttons">
            <button
              className="btn-reply-form attachment"
              type="button"
              disabled={isLoading}
              onClick={handleAddFiles}
            >
              <i className="bi bi-paperclip"></i>
            </button>
            {isLoading ? (
              <div style={{ height: "2rem", color: "var(--primary-color)" }}>
                <LoadingIndicatorCircle />
              </div>
            ) : (
              <button
                className="btn-reply-form"
                type="submit"
                disabled={isSendButtonDisabled}
              >
                <i className="bi bi-send-fill"></i>
              </button>
            )}
          </div>
        </div>
        {warningMessage || dangerMessage ? (
          <div style={{ marginTop: "1rem" }}>
            <WarningAlert message={warningMessage} />
            <DangerAlert message={dangerMessage} />
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default ReplyForm;

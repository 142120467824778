import React from "react";
import LikeButton from "./LikeButton";
import ReplyButton from "./ReplyButton";

const ReplyActions = ({ post }) => {
  return (
    <div className="reply-object-actions">
      <LikeButton post={post} />
      <ReplyButton post={post} />
    </div>
  );
};

export default ReplyActions;

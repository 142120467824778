// src/components/Buttons/PlayButton.jsx

import React from "react";
import { handleLink } from "../Utils/LinkHandler";

const LinkButton = ({ link }) => {
  const handleOpenInBrowser = () => {
    handleLink(link);
  };

  return (
    <div className="btn-square-md" onClick={handleOpenInBrowser}>
      <div>
        <span className="btn-circle-lg-icon">
          <i className="bi bi-box-arrow-up-right"></i>
        </span>
      </div>
    </div>
  );
};

export default LinkButton;

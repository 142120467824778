import React, { useEffect, useState } from "react";
import { toggleUserReaction } from "../../services/Posts/toggleUserReaction";
import { setPostDetails } from "../../stores/postDetailStore";

const LikeButton = ({ post }) => {
  const [postContents, setPostContents] = useState({});
  const [likesCount, setLikesCount] = useState(null);
  const [currentReaction, setCurrentReaction] = useState(null);
  const postContentId = postContents?.id;

  useEffect(() => {
    const postContents = post?.comments?.[0]?.post_contents;
    setPostContents(postContents);
    setLikesCount(postContents?.likes_count);
    setCurrentReaction(postContents?.current_user_reaction);
  }, [post]);

  const handleLikeClick = async () => {
    // Store the previous state
    const previousLikesCount = likesCount;
    const previousReaction = currentReaction;

    // most likely the response will be successful, so we can update the state immediately
    // if the response fails, we will revert the state so that the user can try again
    setCurrentReaction(currentReaction === 1 ? 0 : 1);
    setLikesCount(currentReaction === 1 ? likesCount - 1 : likesCount + 1);

    const response = await toggleUserReaction(postContentId);

    // if successfull, update the store with the new data
    if (!response.error) {
      setPostDetails(response);
    } else {
      // if failed then revert the state
      setCurrentReaction(previousReaction);
      setLikesCount(previousLikesCount);
    }
  };

  return (
    <div className="btn-icon sm" onClick={handleLikeClick}>
      {currentReaction === 1 ? (
        <i
          className="bi bi-heart-fill"
          style={{ color: "var(--heart-color)" }}
        ></i>
      ) : (
        <i className="bi bi-heart"></i>
      )}
      {likesCount > 0 && <span className="sm bold">{likesCount}</span>}
    </div>
  );
};

export default LikeButton;

import { useNavigate } from "react-router-dom";

function useCollectiveNavigation() {
  const navigate = useNavigate();

  return (collectiveId, replace = false) => {
    navigate(`/studio/collective/${collectiveId}`, { replace });
  };
}

function useProjectNavigation() {
  const navigate = useNavigate();

  return (collectiveId, projectId, replace = false) => {
    navigate(`/studio/collective/${collectiveId}?projectId=${projectId}`, {
      replace,
    });
  };
}

function usePostNavigation() {
  const navigate = useNavigate();

  return (collectiveId, parentPostId, childPostId, replace = false) => {
    const collectiveUrlPart = `/studio/collective/${collectiveId}`;
    const postUrlPart = parentPostId ? `?&postId=${parentPostId}` : "";
    const childPostUrlPart = childPostId ? `&childPostId=${childPostId}` : "";

    navigate(`${collectiveUrlPart}${postUrlPart}${childPostUrlPart}`, {
      replace,
    });
  };
}

export { useCollectiveNavigation, useProjectNavigation, usePostNavigation };

//src/components/DetailArea/CollectiveDetail/Components/MembersCard.jsx

import React from "react";
import { Table } from "reactstrap";
import { Card, CardRow } from "../../../Utils/CustomCard";
import EditButton from "../../../Buttons/EditButton";
import OtherUserProfileButton from "../../../Buttons/OtherUserProfileButton";
import {
  editCollectiveMembersModalOpenStore,
  openUserDetailModal,
} from "../../../../stores/modalStore";
import AddMemberButton from "../../../Buttons/AddMemberButton";

function MembersCard({ title, members, isLoading, isEditable }) {
  const handleEditCollectiveMembers = () => {
    editCollectiveMembersModalOpenStore.set(true);
  };

  const handleUserClick = (userId) => {
    openUserDetailModal(userId);
  };

  return (
    <Card title={title} isLoading={isLoading}>
      <CardRow>
        <Table>
          <thead>
            <tr>
              <th>Member</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member, index) => (
              <tr key={index}>
                <td
                  className="table-cell"
                  onClick={() => handleUserClick(member.user.id)}
                  style={{ cursor: "pointer" }}
                >
                  <OtherUserProfileButton user={member.user} size="1.8rem" />
                  <span className="text-wrap-1">{member.user.username}</span>
                </td>
                <td>{member.role}</td>
              </tr>
            ))}
            {isEditable && (
              <tr>
                <td>
                  <div
                    className="table-cell"
                    onClick={handleEditCollectiveMembers}
                    style={{ cursor: "pointer" }}
                  >
                    <AddMemberButton />
                    <p className="text-overflow-trim short text-accented">
                      Add Members
                    </p>
                  </div>
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardRow>
      <EditButton
        isEditable={isEditable}
        onClick={handleEditCollectiveMembers}
      />
    </Card>
  );
}

export default MembersCard;

// src/services/UserInvite/inviteNewUser.js

import axiosInstance from "../Utils/axiosInstance";

const inviteNewUser = async (email, message, collectivesToJoin) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";
  let inviteUrl = "";

  try {
    const response = await axiosInstance.post(
      `/api/user-invites/invite_new_user/`,
      {
        recipient_email: email,
        custom_message: message,
        collectives_to_join: collectivesToJoin,
      },
    );
    successMessage = response.data.detail.message;
    inviteUrl = response.data.detail.invite_url;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }

  return { successMessage, warningMessage, dangerMessage, inviteUrl };
};

export { inviteNewUser };

// src/services/Collectives/createProject.js

import axiosInstance from "../Utils/axiosInstance";

const createProject = async (
  collectiveId,
  projectTitle,
  projectDescription,
  projectImage,
) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";
  let newProjectId = null;

  const url = `/api/collectives/${collectiveId}/create_project/`;

  // call the API
  try {
    // Create a new FormData object
    const formData = new FormData();

    formData.append("title", projectTitle);
    formData.append("description", projectDescription);
    formData.append("project_image", projectImage);

    const response = await axiosInstance.post(url, formData);
    successMessage = "Project created successfully";
    newProjectId = response.data.id;
  } catch (error) {
    console.log(error);
    if (error.response.data.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, warningMessage, dangerMessage, newProjectId };
};

export { createProject };

// src/services/Auth/LogIn.jsx

import axios from "axios";
import logOut from "./logOut.js";
import { setIsAuthenticated } from "../../stores/authStore.js";

export const refreshToken = async () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    logOut();
    return;
  }

  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/auth/token/refresh/`,
      {
        refresh: refreshToken,
      },
    );
    localStorage.setItem("accessToken", response.data.access);
    setIsAuthenticated();
  } catch (err) {
    if (err.response?.status === 401) logOut();
  }
};

import React from "react";
import { UncontrolledTooltip } from "reactstrap";

function TooltipIcon({ toolTipText }) {
  return (
    <>
      <div className="tooltip-icon-container">
        <i className="bi bi-info-circle tooltip-icon" id="TooltipIcon"></i>
        <UncontrolledTooltip target="TooltipIcon" placement="bottom">
          {toolTipText}
        </UncontrolledTooltip>
      </div>
    </>
  );
}

export default TooltipIcon;

// src/services/Collectives/editCollectiveMembers.js

import axiosInstance from "../Utils/axiosInstance";

const editCollectiveMembers = async (collectiveId, userIds) => {
  const url = `/api/collectives/${collectiveId}/edit_members/`;

  // Define states
  let successMessage = "";
  let dangerMessage = "";
  let responseData = null;

  // call the API
  try {
    const response = await axiosInstance.patch(url, {
      user_ids: userIds,
    });

    successMessage = "Members added successfully";
    responseData = response.data;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, dangerMessage, responseData };
};

export { editCollectiveMembers };

//src/pages/HomePage.jsx

import React, { useEffect } from "react";
import MCSLogoText from "../assets/images/logo-text.png";
import LogInForm from "../components/Forms/LogInForm";
import HorizontalDivider from "../components/Utils/HorizontalDivider";
// import useScrollToElement from "../hooks/useScrollToElement";
import "../styles/home-page.css";

const HomePage = () => {
  // // custom hook to scroll to the element from the url when it is rendered
  // useScrollToElement();

  useEffect(() => {
    const adjustCardHeights = () => {
      const cards = document.querySelectorAll(".feature-card");
      let maxHeight = 0;

      cards.forEach((card) => (card.style.height = "auto")); // Reset heights
      cards.forEach((card) => {
        if (card.offsetHeight > maxHeight) {
          maxHeight = card.offsetHeight;
        }
      });
      cards.forEach((card) => (card.style.height = `${maxHeight}px`)); // Apply max height
    };

    adjustCardHeights(); // Adjust heights on load

    const handleResize = () => adjustCardHeights();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  return (
    <div className="main-layout">
      <div className="container">
        <div className="page">
          {/* Header section with auth form  */}

          <div className="section-1">
            <div className="header fadeInLeft">
              <div className="logo-container">
                <a href="/">
                  <img src={MCSLogoText} alt="MCS logo with text" />
                </a>
              </div>
              <p className="xl text-muted">
                A community for musicians, artists, audio engineers, and
                producers to share, discuss, and create music together.
              </p>
            </div>
            <div className="auth-form fadeInRight">
              <LogInForm />
            </div>
          </div>
          <div style={{ width: "15rem", margin: "0 auto" }}>
            <HorizontalDivider />
          </div>

          {/* feature section */}

          <div className="section-2 fadeInLeft">
            <h1>
              Begin channeling inspiration into your next creative journey.
            </h1>
            <div className="feature-card-container">
              <div className="feature-card">
                <div className="icon-container">
                  <i className="bi bi-card-list"></i>
                </div>
                <div>
                  <h2>Create a Project</h2>
                  <p className="text-muted">
                    Start with a concept, finish with a masterpiece. Whether
                    you're conceptualizing an album or iterating through the
                    final mastering stage, MCS provides a workspace organized
                    for your creative process.
                  </p>
                </div>
              </div>
              <div className="feature-card">
                <div className="icon-container">
                  <i className="bi bi-soundwave"></i>
                </div>
                <div>
                  <h2>Post Your Tracks</h2>
                  <p className="text-muted">
                    Ignite conversations by sharing your music within a project,
                    or within our MCS Community. Invite others to share
                    feedback, add instrumentation, iterate through new ideas, or
                    have conversations that refine your sound.
                  </p>
                </div>
              </div>
              <div className="feature-card">
                <div className="icon-container">
                  <i className="bi bi-people"></i>
                </div>
                <div>
                  <h2>Collaborate</h2>
                  <p className="text-muted">
                    Connect with like-minded musicians, artists, and producers
                    in an environment designed for collaboration. Exchange
                    ideas, blend genres, and co-create within a platform that
                    fosters collective creativity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "15rem", margin: "0 auto" }}>
            <HorizontalDivider />
          </div>

          {/* Call to action buttons */}

          <div className="button-container fadeInRight">
            <h1>Start collaborating today.</h1>
            <div className="logo-container">
              <a href="/">
                <img src={MCSLogoText} alt="MCS logo with text" />
              </a>
            </div>
            <button
              className="btn-primary"
              onClick={() => (window.location.href = "/")}
            >
              <h2>Log In</h2>
            </button>
            <button
              className="btn-outline"
              onClick={() => (window.location.href = "/register")}
            >
              <h2>Join the Community</h2>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

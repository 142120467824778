// src/services/Posts/getParentTree.js

import axiosInstance from "../Utils/axiosInstance";

const getParentTree = async (postId) => {
  try {
    const response = await axiosInstance.get(
      `/api/posts/${postId}/get_parent_tree`,
    );
    return response.data;
  } catch (error) {}
};

export { getParentTree };

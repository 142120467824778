//src/layouts/ProtectedLayout.jsx

import React, { useEffect } from "react";
import NavBarProtected from "../components/NavBar/ProtectedNavBar";
import ProtectedFooter from "../components/Footer/ProtectedFooter";
import { confirmAuthentication } from "../stores/authStore";

const ProtectedLayout = ({ children }) => {
  useEffect(() => {
    // confirmAuth logic will automitcally logout and redirect so no handling needed here
    confirmAuthentication();
  }, []);

  return (
    <div className="viewport-page-layout">
      <NavBarProtected />
      {children}
      <ProtectedFooter />
    </div>
  );
};

export default ProtectedLayout;

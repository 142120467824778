import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getNotificationsCount } from "../../services/Notifications/notificationsCount";

const NotificationsButton = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const fetchNotificationsCount = async () => {
      try {
        const notificationsCount = await getNotificationsCount();
        setNotificationsCount(notificationsCount?.unread_notifications);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotificationsCount();
  }, []);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle tag="div" className="btn-circle-lg">
        <div>
          <span className="btn-circle-lg-icon">
            <i className="bi bi-bell-fill"> </i>
          </span>
          {notificationsCount > 0 && (
            <span className="notification-bubble">{notificationsCount}</span>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {/* <DropdownItem>Notification 1</DropdownItem>
        <DropdownItem>Notification 2</DropdownItem>
        <DropdownItem divider /> */}
        <DropdownItem tag={Link} to={`/studio/notifications`}>
          View all notifications
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationsButton;

//src/components/PostDetail/PostDetail.jsx

import React, { useEffect, useState } from "react";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton.jsx";
import { useStore } from "@nanostores/react";
import { collectiveLoadingStore } from "../../../stores/collectiveDetailStore.js";
import {
  allPostsStore,
  activePostIdStore,
  setPostIdToRenderChildPostsArray,
  highlightedPostsArrayStore,
  markPostAsRead,
  fetchPostDetail,
} from "../../../stores/postDetailStore.js";
import ChildPostsContainer from "../../ReplyContainer/ChildPostsContainer.jsx";
import ReplyActions from "../../Buttons/ReplyActions.jsx";
import UnreadPostIcon from "../../Icons/UnreadPostIcon.jsx";
import TrackDetail from "./PostDetailTrack.jsx";
import PostDetailLoading from "./PostDetailLoading.jsx";
import AttachmentsContainer from "./PostDetailAttachments.jsx";
import { formatPostTimestamp } from "../../Utils/formatTime.js";
import JSONtoHTMLComponent from "../../Utils/JSONtoHTMLComponent.jsx";

const PostDetailView = () => {
  // get the active post id from the store
  const activePostId = useStore(activePostIdStore);

  // get the active post details from the store
  const activePostDetails = useStore(allPostsStore)[activePostId] || {};

  // set the details from the active post
  const user = activePostDetails?.user;
  const userName = user?.username;
  const postCreatedAt = formatPostTimestamp(activePostDetails?.created_at);

  const postTitle = activePostDetails?.title;
  const postComment = activePostDetails?.comments?.[0]?.comment || "";
  const postCommentRichText =
    activePostDetails?.comments?.[0]?.rich_text_comment || "";
  const track = activePostDetails?.tracks?.[0] || "";

  // get the array of highlighted posts from the array store to determine if the post should be highlighted
  const highlightedPostArray = useStore(highlightedPostsArrayStore);
  const isPostHighlighted = highlightedPostArray.includes(activePostId);

  // determine if the collective itself is loading
  const collectiveIsLoading = useStore(collectiveLoadingStore);

  // state variables
  const [isUnread, setIsUnread] = useState(false);

  useEffect(() => {
    if (activePostId) {
      fetchPostDetail(activePostId).then(() => {});
    }
  }, [activePostId]);

  // set the post ID to render child posts when active post ID changes
  // reply button will render and fetch the child posts once the array is updated with the corresponding id
  useEffect(() => {
    if (activePostId) {
      setPostIdToRenderChildPostsArray(activePostId);
    }
  }, [activePostId]);

  const handlePostClick = () => {
    setIsUnread(false);
    markPostAsRead(activePostId);
  };

  // if active post details are is loading or the post itself is loading
  if (!activePostDetails || collectiveIsLoading) {
    return <PostDetailLoading />;
  }

  // render the post details
  return (
    <div className="detail-area">
      <div
        className={`track-container ${isPostHighlighted ? "highlighted" : ""}`}
        onClick={handlePostClick}
      >
        <div className="track-details">
          <OtherUserProfileButton user={user} size="2.5rem" />
          <div className="track-info">
            <div className="track-detail-title">
              {isUnread && <UnreadPostIcon />}
              <h4 className="text-break">{postTitle}</h4>
            </div>
            <div className="reply-object-header">
              <p className="md bold text-accented">
                <span>{userName}</span>
              </p>
              <p className="sm text-muted">
                <span>{postCreatedAt}</span>
              </p>
              <p className="sm text-muted">
                <span>#{activePostId}</span>
              </p>
            </div>
          </div>
        </div>
        {postComment ? (
          <div className="track-comment-container">
            <JSONtoHTMLComponent
              json={postCommentRichText}
              text={postComment}
            />
          </div>
        ) : (
          <p
            style={{ color: "var(--text-muted)" }}
            className="track-comment-container text-break"
          >
            Loading comment...
          </p>
        )}
        <TrackDetail track={track} />
        <AttachmentsContainer post={activePostDetails} />
      </div>
      <ReplyActions post={activePostDetails} />
      <div className="reply-section">
        <ChildPostsContainer post={activePostDetails} />
      </div>
    </div>
  );
};

export default PostDetailView;

// src/pages/FilePage.jsx

import React from "react";

const FilePage = () => {
  const filePath = window.location.pathname.split("/file/")[1];
  const fileUrl = `https://${filePath}`;

  return (
    <div className="main-layout" style={{ padding: "1rem" }}>
      <iframe
        src={fileUrl}
        style={{
          width: "100%",
          height: "80dvh",
          border: "none",
          borderRadius: ".5rem",
        }}
        title="Media Viewer"
      ></iframe>
    </div>
  );
};

export default FilePage;

import React from "react";

import MCSLogoText from "../../assets/images/logo-text.png";

const NavBarPublic = () => {
  return (
    <div className="navbar">
      <div className="navbar-contents">
        <div className="navbar-left">
          <div
            className="logo-container"
            style={{ width: "7rem", height: "auto" }}
          >
            <a href="/">
              <img src={MCSLogoText} alt="MCS logo with text" />
            </a>
          </div>
        </div>
        <div className="navbar-right">
          <button
            className="btn-primary"
            onClick={() => (window.location.href = "/")}
          >
            <h5>Log In</h5>
          </button>
          <button
            className="btn-outline"
            onClick={() => (window.location.href = "/register")}
          >
            <h5>Join the Community</h5>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBarPublic;

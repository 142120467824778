//src/components/ReplyContainer/ChildPostsContainer.jsx

import React, { useState, useEffect } from "react";
import { useStore } from "@nanostores/react";
import {
  allPostsStore,
  renderChildPostIdsArrayStore,
} from "../../stores/postDetailStore.js";
import ReplyForm from "../Forms/ReplyForm";
import ReplyCard from "./ReplyCard.jsx";

const ChildPostsContainer = ({ post }) => {
  const postId = post?.id;
  const collectiveId = post?.collective;
  // Use the store to get all posts
  const allPosts = useStore(allPostsStore);
  // Use the store to get the array of post IDs that should render child posts
  const renderChildPostIdsArray = useStore(renderChildPostIdsArrayStore);
  // Retrieve the child posts from the post with the post id
  const childPosts = post?.children?.map((childId) => allPosts[childId]) || [];
  // Check to determine if child posts should be rendered
  const postIdInChildPostsArray = renderChildPostIdsArray.includes(postId);
  // use a state to determine if the component should rendered
  // this avoids the component being updated when several items in the store update
  const [shouldRender, setShouldRender] = useState(false);

  // check if the post ID is in the array to determine if the component should render
  useEffect(() => {
    if (postIdInChildPostsArray) {
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
  }, [postIdInChildPostsArray]);

  // Render the child posts if the post ID is in the array
  if (shouldRender) {
    // add a key to the parent div to prevent react from reusing the same component for a different post
    return (
      <div key={postId} className="child-post-container">
        {childPosts
          .sort((a, b) => a.id - b.id)
          .map((childPost) => (
            <ReplyCard key={childPost.id} childPost={childPost} />
          ))}
        <ReplyForm postId={postId} collectiveId={collectiveId} />
      </div>
    );
  }

  // Otherwise, then do not render anything
  if (!shouldRender) {
    return null;
  }
};

export default ChildPostsContainer;

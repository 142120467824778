// src/services/Auth/passwordResetRequest.js

import axiosInstance from "../Utils/axiosInstance";

const requestPasswordReset = async (email) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  // validate email is not blank
  if (email === "") {
    warningMessage = "Please enter your email address.";
    return { successMessage, warningMessage, dangerMessage };
  }

  try {
    await axiosInstance.post(`/api/auth/password-reset/`, {
      email: email,
    });
    successMessage = `<p>If your email is in our system, you will receive a password reset email shortly!</p>
    <p>Please make sure to check your spam folder.</p>
    <p>Redirecting you to the home page...</p>`;
  } catch (error) {
    // silently catch the error to prevent leaking user information
  }

  return { successMessage, warningMessage, dangerMessage };
};

export { requestPasswordReset };

// src/services/Users/currentUser.js

import axiosInstance from "../Utils/axiosInstance"; // Adjust the path as needed

const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get("/api/users/get_current_user");
    return response.data;
  } catch (error) {}
};

export { getCurrentUser };

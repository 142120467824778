import React from "react";

function ProfileImage(props) {
  const userName = props.userName;
  const userInitials = userName
    ? userName
        .split(" ")
        .slice(0, 3)
        .map((word) => word[0])
        .join("")
        .toUpperCase()
    : "";
  const profileImage = props.profileImage;
  const imageSize = props.size || "1.2rem";

  const userInitialsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: `calc(${imageSize} * 0.6)`, // scales the size of the text to fit appropriately within the image
    fontWeight: "200",
    color: "var(--text-muted)",
  };

  // if the image is a file, convert it to a URL
  // set to let so the value can be changed if the image is a file
  let image = profileImage;
  if (image instanceof File) {
    image = URL.createObjectURL(image);
  }

  return (
    <div
      className="profile-icon"
      style={{ width: imageSize, height: imageSize }}
    >
      {image ? (
        <img src={image} alt={`Username: ${userName}`} />
      ) : (
        <span
          style={userInitialsStyle}
          className="user-initials text-prevent-selecting"
        >
          {userInitials}
        </span>
      )}
    </div>
  );
}

export default ProfileImage;

// src/services/Tracks/downloadTrack.js

import axiosInstance from "../Utils/axiosInstance";

const downloadTrack = async (trackId) => {
  try {
    const response = await axiosInstance.get(
      `/api/tracks/${trackId}/download`,
      {
        responseType: "blob", // Important for handling binary data
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error downloading attachment:",
      error.response ? error.response.data : error,
    );
  }
};

export { downloadTrack };

// src/services/Auth/logOut.js
import { setIsNotAuthenticated } from "../../stores/authStore.js";

const logOut = async () => {
  localStorage.clear();
  setIsNotAuthenticated();
  sessionStorage.setItem("lastVisitedURL", window.location.pathname);
  window.location.href = "/";
};

export default logOut;

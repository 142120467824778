//src/components/Utils/CustomCard.jsx

import React from "react";

const CardSection = ({ children }) => {
  return <div className="custom-card-section">{children}</div>;
};

const Card = ({ children, title, isLoading }) => {
  if (isLoading) {
    return (
      <div className="custom-card">
        <div className="loading-component bar width-sm"></div>
        <div className="loading-component bar width-md"></div>
        <div className="loading-component bar width-xlg"></div>
        <div className="loading-component bar width-lg"></div>
      </div>
    );
  }

  return (
    <div className="custom-card">
      {title ? <h3>{title}</h3> : null}
      {children}
    </div>
  );
};

const CardRow = ({ children }) => {
  return <div className="custom-card-row">{children}</div>;
};

const CardColumn = ({ children }) => {
  return <div className="custom-card-column">{children}</div>;
};

export { CardSection, Card, CardRow, CardColumn };

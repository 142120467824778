//src/components/AudioPlayer/WaveformVisualizer.jsx
import React, { useEffect, useRef, useState } from "react";
import WaveformBar from "./WaveformBar";
import { fetchAmplitudeData } from "./fetchAmplitudeData";
import { useStore } from "@nanostores/react";
import {
  activeTrackStore,
  activeTrackCurrentTimeStore,
} from "../../../stores/activeTrackStore.js";

const WaveformVisualizer = ({ track }) => {
  // track details from the parent component
  const currentTrackId = track?.id;
  const amplitudeDataFileUrl = track?.audio_file_amplitude_data;

  // track details from the store
  const activeTrack = useStore(activeTrackStore);
  const activeTrackId = activeTrack?.id;
  const activeTrackCurrentTime = useStore(activeTrackCurrentTimeStore);

  // check if the current track is playing
  // this is determined in this parent component to limit the rendering of the waveform bars
  const currentTrackIsActive = activeTrackId === currentTrackId;

  // reference for rendering
  const barContainerRef = useRef(null);

  // state variables
  const [numberOfBars, setNumberOfBars] = useState(0);
  const [waveformData, setWaveformData] = useState([]);
  const [trackDuration, setTrackDuration] = useState(null);

  // set a desired width for each bar
  const barWidth = 4;

  // fetch the amplitude data for the track
  useEffect(() => {
    const getAmplitudeData = async () => {
      const amplitudeData = await fetchAmplitudeData(amplitudeDataFileUrl);
      setWaveformData(amplitudeData);

      // Find the maximum time value in the amplitude data
      const maxTime = Math.max(...amplitudeData.map((data) => data.time));
      setTrackDuration(maxTime);
    };

    if (amplitudeDataFileUrl) {
      getAmplitudeData();
    } else {
      setWaveformData([]);
    }
  }, [amplitudeDataFileUrl]);

  // dynamically set the number of bars based on the bar width variable
  useEffect(() => {
    const updateWidth = () => {
      if (barContainerRef.current) {
        const containerWidth = barContainerRef.current.offsetWidth;
        const newNumberOfBars = Math.floor(containerWidth / barWidth);
        setNumberOfBars(newNumberOfBars);
      }
    };

    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [barWidth]);

  return (
    <div ref={barContainerRef} className="waveform-visualizer-container">
      {Array.from({ length: numberOfBars }, (_, i) => {
        const maxTime = ((i + 1) * trackDuration) / numberOfBars;
        const minTime = (i * trackDuration) / numberOfBars;
        const barIsWithinActiveTimeRange =
          currentTrackIsActive && activeTrackCurrentTime > minTime;
        const dataPoints = waveformData.filter(
          (data) => data.time >= minTime && data.time < maxTime,
        );
        const aggregatedAmplitude =
          dataPoints.length > 0
            ? dataPoints.reduce((sum, data) => sum + data.amplitude, 0) /
              dataPoints.length
            : 0;
        const barHeight = (aggregatedAmplitude * 100).toFixed(2) + "%";
        const isClickable = waveformData.length > 0;

        return (
          <WaveformBar
            key={i}
            track={track}
            minTime={minTime}
            barHeight={barHeight}
            barWidth={barWidth}
            barIsWithinActiveTimeRange={barIsWithinActiveTimeRange}
            isClickable={isClickable}
          />
        );
      })}
    </div>
  );
};

export default WaveformVisualizer;

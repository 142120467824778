// src/services/Tracks/playlistTracks.js

import axiosInstance from "../Utils/axiosInstance";

const getPlaylistTracks = async (trackId, projectId) => {
  // if projectId is provided, append it to the url
  const projectPart = projectId ? `?project_id=${projectId}` : "";

  // define the url with the conditional project appendage
  const url = `/api/tracks/${trackId}/get_playlist/${projectPart}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {}
};

export { getPlaylistTracks };

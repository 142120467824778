//src/pages/PasswordResetRequestPage.jsx

import React from "react";
import { useParams } from "react-router-dom";
import MCSLogoText from "../assets/images/logo-text.png";
import PasswordResetConfirmForm from "../components/Forms/PasswordResetConfirmForm";
import HorizontalDivider from "../components/Utils/HorizontalDivider";
import "../styles/home-page.css";

const PasswordResetConfirmPage = () => {
  const { uid, token } = useParams();

  return (
    <div className="main-layout">
      <div className="container">
        <div className="page">
          {/* Header section with register form  */}
          <div className="register-page-section-1">
            <h1>Confirm password reset.</h1>
            <div
              className="logo-container"
              style={{
                maxWidth: "10rem",
                height: "auto",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <a href="/">
                <img src={MCSLogoText} alt="MCS logo with text" />
              </a>
            </div>
            <div className="register-form" id="login">
              <PasswordResetConfirmForm uid={uid} token={token} />
            </div>
          </div>
          <div style={{ width: "15rem", margin: "0 auto" }}>
            <HorizontalDivider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirmPage;

//src/components/AudioPlayer/WaveformBar.jsx

import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { updateTrackCurrentTime } from "../../../stores/activeTrackStore.js";
import { formatAudioTimestamp } from "../../Utils/formatTime.js";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton.jsx";

const WaveformBar = ({
  track,
  minTime,
  barHeight,
  barWidth,
  barIsWithinActiveTimeRange,
  isClickable,
}) => {
  // generate a unique tooltip ID for each bar on the entire page
  const tooltipTimeRef = useRef(
    `Tooltip-${Math.random().toString(36).substr(2, 9)}`,
  );

  // track comment variables
  const commentIconRef = useRef(
    `CommentTooltip-${Math.random().toString(36).substr(2, 9)}`,
  );
  const trackComment = null; // TODO: comment data for the bar to render the comment icon

  // // testing for rending comment icons at specific bars
  // let trackComment =
  //   barNumber === 22 || barNumber === 44 || barNumber === 103 ? "hi" : null;

  const handleBarClick = () => {
    updateTrackCurrentTime(track, minTime);
  };

  // Event handler to stop propagation and prevent default
  const handleCommentIconClick = (e) => {
    e.stopPropagation(); // stops the propagation of the event that
    // TODO - add the link to the comment functionalty
    console.log("comment clicked");
  };

  if (isClickable) {
    return (
      <div
        className="waveform-visualizer-bar-container"
        onClick={handleBarClick}
        id={tooltipTimeRef.current} // Use the unique ref as the ID
        style={{ maxWidth: `${barWidth}px` }} // set a max width to prevent "wonky" bar sizes
      >
        <div
          className={`waveform-visualizer-bar ${barIsWithinActiveTimeRange ? "active" : ""}`}
          style={{ height: barHeight }}
        ></div>
        <UncontrolledTooltip
          placement="top"
          target={tooltipTimeRef.current} // Reference the unique ID for the tooltip
        >
          {formatAudioTimestamp(minTime)}
        </UncontrolledTooltip>
        {trackComment ? (
          <div
            className="waveform-visualizer-comment-icon"
            id={commentIconRef.current}
          >
            <div onClickCapture={handleCommentIconClick}>
              <OtherUserProfileButton user={track?.user} size={"1rem"} />
            </div>
            <UncontrolledTooltip
              placement="right"
              target={commentIconRef.current}
            >
              {"@joek: pls add way more bass here bro. wtf were you thinking?"}
            </UncontrolledTooltip>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="waveform-visualizer-bar-container disabled">
      <div
        className="waveform-visualizer-bar"
        style={{ height: barHeight }}
      ></div>
    </div>
  );
};

export default WaveformBar;

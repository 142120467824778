export async function fetchAmplitudeData(amplitudeDataFileUrl) {
  if (!amplitudeDataFileUrl) {
    return null;
  }

  try {
    const response = await fetch(amplitudeDataFileUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const amplitudeData = await response.json();
    return amplitudeData;
  } catch (error) {
    console.error("Error processing audio file:", error);
    return null;
  }
}

// src/stores/collectiveDetailStore.js

import { atom } from "nanostores";
import { getSidebarData } from "../services/Collectives/getSidebarData";
import { setPostDetails } from "./postDetailStore";

export const collectiveIdStore = atom(null);
export const collectiveTitleStore = atom(null);
export const collectiveImageStore = atom(null);
export const collectiveDescriptionStore = atom(null);
export const collectiveParentPostsStore = atom([]);
export const collectiveUserRoleStore = atom(null);
export const collectiveListStore = atom([]);
export const collectiveUsersStore = atom([]);
export const projectListStore = atom([]);
export const projectIdStore = atom(null);
export const projectTitleStore = atom(null);
export const projectImageStore = atom(null);
export const projectDescriptionStore = atom(null);
export const collectiveLoadingStore = atom(true);
export const collectiveErrorStore = atom(null);

export function clearCollectiveDetailsStore() {
  collectiveIdStore.set(null);
  collectiveTitleStore.set(null);
  collectiveImageStore.set(null);
  collectiveDescriptionStore.set(null);
  collectiveParentPostsStore.set([]);
  collectiveUserRoleStore.set(null);
  collectiveListStore.set([]);
  collectiveUsersStore.set([]);
  projectListStore.set([]);
  projectIdStore.set(null);
  projectTitleStore.set(null);
  projectImageStore.set(null);
  projectDescriptionStore.set(null);
  collectiveLoadingStore.set(true);
  collectiveErrorStore.set(null);
}

export async function fetchCollectiveDetails(collectiveId, projectId) {
  collectiveLoadingStore.set(true);
  try {
    // fetch Collective detail from the API with a projectId if it is passed
    // backend will handle instances where project ID is null and just return the collective
    // If a projectID is provided, then the collective will be filtered to the project
    const collectiveDetails = await getSidebarData(collectiveId, projectId);
    // Set the collective details
    collectiveIdStore.set(collectiveDetails.id);
    collectiveTitleStore.set(collectiveDetails.title);
    collectiveImageStore.set(collectiveDetails.collective_image);
    collectiveDescriptionStore.set(collectiveDetails.description);
    collectiveUserRoleStore.set(collectiveDetails.user_role);
    // Set the project details if they exist
    const projectDetails = collectiveDetails?.project;
    projectIdStore.set(projectDetails?.id);
    projectTitleStore.set(projectDetails?.title);
    projectImageStore.set(projectDetails?.project_image);
    projectDescriptionStore.set(projectDetails?.description);
    // Set the parent posts
    if (collectiveDetails.posts && Array.isArray(collectiveDetails.posts)) {
      collectiveParentPostsStore.set(collectiveDetails.posts);

      // Update PostDetailStore with the fetched posts
      collectiveDetails.posts.forEach((post) => {
        setPostDetails(post);
      });
    }
    // Set the collective list
    if (
      collectiveDetails.collective_list &&
      Array.isArray(collectiveDetails.collective_list)
    ) {
      collectiveListStore.set(collectiveDetails.collective_list);
    }
    // Set the project list
    if (
      collectiveDetails.project_list &&
      Array.isArray(collectiveDetails.project_list)
    ) {
      projectListStore.set(collectiveDetails.project_list);
    }
    // get the users by iterating over the members of the collective
    if (collectiveDetails.members && Array.isArray(collectiveDetails.members)) {
      const users = collectiveDetails.members.map((member) => member.user);
      collectiveUsersStore.set(users);
    }
    collectiveLoadingStore.set(false);
  } catch (error) {
    collectiveErrorStore.set(error);
    collectiveLoadingStore.set(false);
  }
}

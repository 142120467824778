import React from "react";
import { createParentPostModalOpenStore } from "../../stores/modalStore";

const handleAddPostClick = () => {
  createParentPostModalOpenStore.set(true);
};

const AddParentPostButton = () => {
  return (
    <div
      className="btn-circle-md btn-add-parent-post"
      onClick={handleAddPostClick}
    >
      <div>
        <span className="btn-circle-lg-icon">
          <i className="bi bi-pencil-square"></i>
        </span>
      </div>
    </div>
  );
};

export default AddParentPostButton;

// src/components/ReplyCard.jsx

import React, { useState } from "react";
import ProfileImageContainer from "./ProfileImageContainer.jsx";
import ReplyActions from "../Buttons/ReplyActions.jsx";
import ChildPostsContainer from "./ChildPostsContainer.jsx";
import {
  formatAudioTimestamp,
  formatPostTimestamp,
} from "../Utils/formatTime.js";
import {
  updateTrackCurrentTime,
  playTrack,
} from "../../stores/activeTrackStore.js";
import AttachmentsContainer from "./AttachmentsContainer.jsx";
import { useStore } from "@nanostores/react";
import {
  highlightedPostsArrayStore,
  markPostAsRead,
  renderChildPostIdsArrayStore,
} from "../../stores/postDetailStore.js";
import UnreadPostIcon from "../Icons/UnreadPostIcon.jsx";
import JSONtoHTMLComponent from "../Utils/JSONtoHTMLComponent.jsx";

function ReplyCard({ childPost }) {
  const [isUnread, setIsUnread] = useState(false);
  const post = childPost;
  const postId = post?.id;
  const user = post?.user;
  const userName = user?.username;
  const postCreatedAt = formatPostTimestamp(post?.created_at);
  const postComment = post?.comments?.[0]?.comment || "";
  const postCommentRichText = post?.comments?.[0]?.rich_text_comment || "";
  const relatedTrackTimestamp = post?.comments?.[0]?.track_timestamp || "";
  const relatedTrack = post?.comments?.[0]?.related_tracks?.[0] || "";
  const relatedTrackTitle =
    post?.comments?.[0]?.related_tracks?.[0]?.audio_file_name || "";
  // Use the store to get the array of post IDs that should render child posts
  const renderChildPostIdsArray = useStore(renderChildPostIdsArrayStore);
  // Check to determine if child posts should be rendered
  const isReplyExpanded = renderChildPostIdsArray.includes(postId);
  // get the array of highlighted posts from the array store to determine if the post should be highlighted
  const highlightedPostArray = useStore(highlightedPostsArrayStore);
  const isPostHighlighted = highlightedPostArray.includes(postId);

  const handlePostClick = () => {
    setIsUnread(false);
    markPostAsRead(postId);
  };

  const handlePlay = () => {
    updateTrackCurrentTime(relatedTrack, relatedTrackTimestamp);
    playTrack();
  };

  const TrackTimestampContainer = () => {
    if (relatedTrackTimestamp) {
      return (
        <p className="sm text-muted">
          <span>at </span>
          <span className="track-timestamp-link" onClick={handlePlay}>
            {formatAudioTimestamp(relatedTrackTimestamp)}
          </span>
          <span> in: </span>
          <span>{relatedTrackTitle}</span>
        </p>
      );
    }
    return null;
  };

  return (
    <div className="reply-container">
      <div className="reply-object">
        <ProfileImageContainer user={user} isReplyExpanded={isReplyExpanded} />
        <div className="reply-object-contents">
          <div
            className={`reply-object-details ${isPostHighlighted ? "highlighted" : ""}`}
            onClick={handlePostClick}
          >
            <div className="text-overflow-trim reply-object-header">
              {isUnread && <UnreadPostIcon />}
              <p className="md bold text-accented">
                <span>{userName}</span>
              </p>
              <p className="sm text-muted">
                <span>{postCreatedAt}</span>
              </p>
              <p className="sm text-muted">
                <span>#{postId}</span>
              </p>
            </div>
            <div className="reply-object-comment">
              <JSONtoHTMLComponent
                json={postCommentRichText}
                text={postComment}
              />
            </div>
            <div>
              <TrackTimestampContainer />
            </div>
            <AttachmentsContainer post={post} />
          </div>
          <ReplyActions post={post} />
          <div className="reply-object-child-posts">
            <ChildPostsContainer post={post} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReplyCard;

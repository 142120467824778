import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

function IsUserOnlineIcon({ size, isUserOnline }) {
  const maxIconSize = 2;
  const maxOutlineWidth = 0.3;
  const iconSize = `${Math.min(parseFloat(size) * 0.25, maxIconSize)}rem`;
  const outlineWidth = `${Math.min(parseFloat(size) * 0.05, maxOutlineWidth)}rem`;
  const tooltipRef = useRef(null);

  if (!isUserOnline) {
    return null;
  }

  return (
    <div>
      <div
        id="OnlineTooltip"
        className="is-user-online-icon"
        style={{
          height: iconSize,
          width: iconSize,
          outlineWidth: outlineWidth,
        }}
        ref={tooltipRef}
      ></div>
      <UncontrolledTooltip placement="right" target={tooltipRef}>
        Is Active
      </UncontrolledTooltip>
    </div>
  );
}

export default IsUserOnlineIcon;

import React, { useEffect, useState } from "react";
import BaseModal from "./BaseModal";
import { useStore } from "@nanostores/react";
import {
  viewUserDetailModalOpenStore,
  userDetailModalUserIDStore,
  editUserProfileModalOpenStore,
} from "../../stores/modalStore";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton";
import { retrieveUser } from "../../services/Users/retrieveUser";

function ViewUserDetailModal() {
  const showModal = useStore(viewUserDetailModalOpenStore);
  const userId = useStore(userDetailModalUserIDStore);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const userName = userDetails?.username;
  const userBio = userDetails?.bio;
  const isUserSelf = userDetails?.is_self;

  // fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId) {
        setIsLoading(true);
        try {
          const userDetails = await retrieveUser(userId);
          setUserDetails(userDetails);
          setIsLoading(false);
        } catch (err) {}
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleEditProfileClick = () => {
    editUserProfileModalOpenStore.set(true);
  };

  if (isLoading) {
    return (
      <>
        <BaseModal modalTitle="View User Detail" showModal={showModal}>
          <div style={{ textAlign: "center" }}>
            <p>Loading user details...</p>
          </div>
        </BaseModal>
      </>
    );
  }

  return (
    <>
      <BaseModal modalTitle="View User Detail" showModal={showModal}>
        <OtherUserProfileButton user={userDetails} size={"10rem"} />
        <h1 className="text-accented">{userName}</h1>
        <div style={{ textAlign: "center" }}>
          <p>{userBio}</p>
        </div>
        {isUserSelf && (
          <button className="btn-outline" onClick={handleEditProfileClick}>
            Edit My Profile
          </button>
        )}
      </BaseModal>
    </>
  );
}

export default ViewUserDetailModal;

// src/services/Utils/axiosInstance.js

import axios from "axios";
import { refreshToken } from "../Auth/refreshToken.js";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(undefined, async (error) => {
  if (error.response?.status === 401 && error.config && !error.config._retry) {
    error.config._retry = true;
    try {
      await refreshToken();
      error.config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      return axiosInstance(error.config);
    } catch (_) {}
  }
  return Promise.reject(error);
});

export default axiosInstance;

// src/services/Notifications/notificationsCount.js

import axiosInstance from "../Utils/axiosInstance";

const getNotificationsCount = async () => {
  try {
    const response = await axiosInstance.get(`/api/notifications/unread_count`);

    return response.data;
  } catch (error) {}
};

export { getNotificationsCount };

// src/services/Auth/registerUser.js

import axiosInstance from "../Utils/axiosInstance";

const registerUser = async (
  invite_code,
  username,
  email,
  password1,
  password2,
) => {
  // Define message states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  // validate fields are not blank
  if (
    invite_code === "" ||
    password1 === "" ||
    password2 === "" ||
    username === "" ||
    email === ""
  ) {
    warningMessage = "Please complete all fields.";
    return { successMessage, warningMessage, dangerMessage };
  }

  // validate passwords match
  if (password1 !== password2) {
    warningMessage = "Passwords must match.";
    return { successMessage, warningMessage, dangerMessage };
  }

  try {
    await axiosInstance.post(`/api/auth/registration/`, {
      invite_code: invite_code,
      username: username,
      email: email,
      password1: password1,
      password2: password2,
    });
    successMessage = "Registration successful! Redirecting you to login...";
  } catch (error) {
    if (error.response) {
      dangerMessage = Object.values(error.response.data).flat().join(" ");
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }

  return { successMessage, warningMessage, dangerMessage };
};

export { registerUser };

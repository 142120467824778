// src/services/Users/editUserProfile.js

import axiosInstance from "../Utils/axiosInstance";

const editUserProfile = async (
  userBio,
  userImage,
  defaultCollectiveId,
  newPostNotificationFrequency,
  mentionNotificationFrequency,
  reactionNotificationFrequency,
) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  const url = `/api/users/update_profile/`;

  // call the API
  try {
    // Create a new FormData object
    const formData = new FormData();

    formData.append("bio", userBio);

    // append image only if it is a file
    // this is to prevent the backend from trying to handle a url string
    if (userImage instanceof File) {
      formData.append("profile_image", userImage);
    }

    // if image is null, append a null value to the form data
    // this is to handle instances where the user wants to remove the image
    if (userImage === null) {
      formData.append("profile_image", "");
    }

    if (defaultCollectiveId) {
      formData.append("default_collective", defaultCollectiveId);
    }

    if (newPostNotificationFrequency) {
      formData.append(
        "new_post_notification_frequency",
        newPostNotificationFrequency,
      );
    }

    if (mentionNotificationFrequency) {
      formData.append(
        "mention_notification_frequency",
        mentionNotificationFrequency,
      );
    }

    if (reactionNotificationFrequency) {
      formData.append(
        "reaction_notification_frequency",
        reactionNotificationFrequency,
      );
    }

    await axiosInstance.patch(url, formData);
    successMessage = "Profile edited successfully";
  } catch (error) {
    console.log(error);
    if (error.response.data.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, warningMessage, dangerMessage };
};

export { editUserProfile };

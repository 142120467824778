// src/services/Collectives/getCollectiveDetail.js

import axiosInstance from "../Utils/axiosInstance";

const getCollectiveDetail = async (collectiveId, projectId) => {
  const projectPart = projectId ? `?project_id=${projectId}` : "";

  try {
    const response = await axiosInstance.get(
      `/api/collectives/${collectiveId}/retrieve${projectPart}`,
    );

    return response.data;
  } catch (error) {}
};

export { getCollectiveDetail };

// src/components/SideBar/SideBar.jsx

import React, { useEffect } from "react";
import { useStore } from "@nanostores/react";
import {
  collectiveLoadingStore,
  collectiveErrorStore,
} from "../../stores/collectiveDetailStore";
import {
  sidebarIsOpenStore,
  sidebarIsMobileStore,
} from "../../stores/sidebarStore";
import SideBarLoading from "./SideBarLoading";
import SideBarCollectiveHeader from "./SideBarCollectiveHeader";
import SideBarPostsList from "./SideBarPostsList/SideBarPostsList";
import SideBarProjectsList from "./SideBarProjectsList";
import { useCollectiveNavigation } from "../../hooks/navigationHooks";

function Sidebar() {
  const navigateToCollective = useCollectiveNavigation();
  const collectiveDataIsLoading = useStore(collectiveLoadingStore);
  const collectiveDataHasErrored = useStore(collectiveErrorStore);
  const isSidebarOpen = useStore(sidebarIsOpenStore);
  const isSidebarMobile = useStore(sidebarIsMobileStore);
  const sideBarOpenWidth = 768;

  // if collective data has errored, redirect to MCS community
  useEffect(() => {
    if (collectiveDataHasErrored) {
      navigateToCollective(5, { replace: true });
    }
  }, [collectiveDataHasErrored, navigateToCollective]);

  // determine the sidebar state based on the window width
  useEffect(() => {
    function handleResize() {
      const pageWidth = window.innerWidth;
      if (pageWidth > sideBarOpenWidth) {
        sidebarIsMobileStore.set(false);
        // ensures that the sidebar is open if the window is resized not in mobile view
        sidebarIsOpenStore.set(true);
      } else {
        sidebarIsMobileStore.set(true);
      }
    }

    // Call the function to run on initial render
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSidebarClose = () => {
    sidebarIsOpenStore.set(false);
  };

  // dynamic class based on sidebar state
  const sidebarClass = `sidebar ${isSidebarMobile ? "is-mobile" : ""} ${!isSidebarOpen ? "closed" : ""}`;

  if (collectiveDataIsLoading || collectiveDataHasErrored) {
    return (
      <div className={sidebarClass}>
        <SideBarLoading />
      </div>
    );
  }

  return (
    <div>
      {isSidebarMobile && isSidebarOpen && (
        <div
          className="sidebar-mobile-container"
          onClick={handleSidebarClose}
        ></div>
      )}
      <div className={sidebarClass}>
        <SideBarCollectiveHeader />
        <SideBarProjectsList />
        <SideBarPostsList />
      </div>
    </div>
  );
}

export default Sidebar;

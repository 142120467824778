import React, { useState, useRef, useEffect } from "react";

const Accordion = ({ children, header, isOpen, isDisabled }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  const handleAccordionClick = () => {
    if (!isDisabled) {
      setIsAccordionOpen(!isAccordionOpen);
    }
  };

  // Update isAccordionOpen when isOpen changes
  useEffect(() => {
    setIsAccordionOpen(isOpen);
  }, [isOpen]);

  // Update maxHeight when isOpen changes
  useEffect(() => {
    if (isAccordionOpen && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [isAccordionOpen]);

  // Update maxHeight when the number of children changes
  useEffect(() => {
    if (isAccordionOpen && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [children, isAccordionOpen]);

  return (
    <div className="accordion text-prevent-selecting">
      <div
        className={`accordion-header ${isAccordionOpen ? "active" : ""}`}
        onClick={handleAccordionClick}
      >
        {header}
        {isDisabled ? null : (
          <i
            className={`bi bi-chevron-right accordion-chevron-icon ${isAccordionOpen ? "rotate" : ""}`}
          ></i>
        )}
      </div>
      <div
        className={`accordion-content ${!isAccordionOpen ? "closed" : ""}`}
        style={{ maxHeight }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export { Accordion };

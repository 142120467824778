import React from "react";

const AddMemberButton = () => {
  return (
    <div
      className="btn-circle-md btn-create-project"
      style={{ borderRadius: "50%" }}
    >
      <div>
        <span>
          <i className="bi bi-person-plus"></i>
        </span>
      </div>
    </div>
  );
};

export default AddMemberButton;

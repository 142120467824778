// src/services/Collectives/updateProjectPosts.js

import axiosInstance from "../Utils/axiosInstance";

const updateProjectPosts = async (projectId, postsToAdd, postsToRemove) => {
  try {
    const response = await axiosInstance.patch(
      `/api/collectives/update_project_posts/`,
      {
        project_id: projectId,
        posts_to_add: postsToAdd,
        posts_to_remove: postsToRemove,
      },
    );
    return response.data; // return the response
  } catch (error) {}
};

export { updateProjectPosts };

import React from "react";

const OptionsButton = () => {
  return (
    <div className="attachment-options">
      <i className="bi bi-three-dots"></i>
    </div>
  );
};

export default OptionsButton;

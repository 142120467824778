import React from "react";
import BaseModal from "./BaseModal";
import InviteNewUserForm from "../../components/Forms/InviteNewUserForm";
import { useStore } from "@nanostores/react";
import { inviteUserModalOpenStore } from "../../stores/modalStore";
import MCSLogoText from "../../assets/images/logo-text.png";

function InviteNewUserModal() {
  const showModal = useStore(inviteUserModalOpenStore);

  return (
    <>
      <BaseModal modalTitle="Invite New User" showModal={showModal}>
        <div
          className="logo-container"
          style={{
            maxWidth: "10rem",
            height: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <img src={MCSLogoText} alt="MCS logo with text" />
        </div>
        <div className="modal-form">
          <InviteNewUserForm />
        </div>
      </BaseModal>
    </>
  );
}

export default InviteNewUserModal;

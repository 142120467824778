import React from "react";
import ProjectImage from "../Utils/ProjectImage.jsx";
import CreateNewProjectButton from "../Buttons/CreateNewProjectButton.jsx";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  collectiveUserRoleStore,
  projectListStore,
  projectIdStore, // the active project
} from "../../stores/collectiveDetailStore";
import { clearActivePost } from "../../stores/postDetailStore";
import { createProjectModalOpenStore } from "../../stores/modalStore.js";
import { Accordion } from "../Utils/CustomAccordion";
import { useProjectNavigation } from "../../hooks/navigationHooks.js";
import { userIsAdminOwnerOrMember } from "../Utils/permissionsUtils.js";

function SideBarProjectsList() {
  const navigateToProject = useProjectNavigation();
  const collectiveId = useStore(collectiveIdStore);
  const projectList = useStore(projectListStore);
  const activeProjectId = useStore(projectIdStore);
  const currentUserRole = useStore(collectiveUserRoleStore);
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);

  const handleProjectClick = (targetProjectId) => {
    // clear the active post so there isn't a post rendered from the previous project or collective
    clearActivePost();
    navigateToProject(collectiveId, targetProjectId);
  };

  const handleProjectCreateClick = () => {
    createProjectModalOpenStore.set(true);
  };

  // if the user can't edit and there are no projects, don't render the project list
  if (!userCanEdit && !projectList?.length > 0) {
    return null;
  }

  if (activeProjectId) {
    return null;
  }

  return (
    <Accordion header="Collective Projects">
      {projectList
        .filter((project) => project.id !== activeProjectId)
        .map((project, index) => (
          <div
            key={project.id || index}
            onClick={() => handleProjectClick(project.id)}
            className="sidebar-project-card"
          >
            <div className="sidebar-project-title">
              <ProjectImage
                title={project.title}
                image={project.project_image}
                size={"1.8rem"}
              />
              <p className="text-overflow-trim short text-muted">
                {project.title}
              </p>
            </div>
          </div>
        ))}
      {userCanEdit && (
        <div className="sidebar-project-card">
          <div
            className="sidebar-project-title"
            onClick={handleProjectCreateClick}
          >
            <CreateNewProjectButton />
            <p className="text-overflow-trim short text-accented">
              Create Project
            </p>
          </div>
        </div>
      )}
    </Accordion>
  );
}

export default SideBarProjectsList;

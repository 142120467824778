//src/components/DetailArea/CollectiveDetail/Components/CollectiveHeader.jsx

import React from "react";
import ProjectImage from "../../../Utils/ProjectImage";
import { Card, CardRow } from "../../../Utils/CustomCard";
import EditButton from "../../../Buttons/EditButton";

function HeaderCard({
  headerTitle,
  headerSubtitle,
  descriptionTitle,
  description,
  image,
  isLoading,
  isEditable,
  onEditClick,
}) {
  return (
    <>
      <Card isLoading={isLoading}>
        <CardRow>
          <div style={{ position: "relative" }}>
            <ProjectImage title={headerTitle} image={image} size={"6rem"} />
          </div>
          <div style={{ marginTop: "auto", marginLeft: ".5rem" }}>
            <h1 className="xxl text-break text-wrap-2">{headerTitle}</h1>
            <h4 className="text-muted text-break text-wrap-1">
              {headerSubtitle}
            </h4>
          </div>
        </CardRow>
        <EditButton isEditable={isEditable} onClick={onEditClick} />
      </Card>
      <Card title={descriptionTitle} isLoading={isLoading}>
        <CardRow>
          <p>{description}</p>
        </CardRow>
        <EditButton isEditable={isEditable} onClick={onEditClick} />
      </Card>
    </>
  );
}

export default HeaderCard;

// src/services/Users/searchUsers.js

import axiosInstance from "../Utils/axiosInstance";

const searchUsers = async (searchQuery, searchQueryNextPageUrl) => {
  // Define the search query parts
  const searchQueryPart = `?search_query=${searchQuery}`;
  const initialUrl = `/api/users/search/${searchQueryPart}`;

  // remove the domain from the searchQueryNextPageUrl
  const nextPageUrl = searchQueryNextPageUrl
    ? new URL(searchQueryNextPageUrl).pathname +
      new URL(searchQueryNextPageUrl).search
    : null;

  // If the nextPageUrl is available, use it, otherwise use the initialUrl
  const url = nextPageUrl ? nextPageUrl : initialUrl;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    // silently catch the error
  }
};

export { searchUsers };

// src/services/Notifications/markNotificationAsRead.js

import axiosInstance from "../Utils/axiosInstance";

const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await axiosInstance.patch(
      `/api/notifications/${notificationId}/mark_as_read/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const markAllNotificationsAsRead = async () => {
  try {
    const response = await axiosInstance.patch(
      `/api/notifications/mark_all_as_read/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const markAllNotificationsAsUnread = async () => {
  try {
    const response = await axiosInstance.patch(
      `/api/notifications/mark_all_as_unread/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  markNotificationAsRead,
  markAllNotificationsAsRead,
  markAllNotificationsAsUnread,
};

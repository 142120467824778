import React from "react";
import ProfileImage from "../Utils/ProfileImage";
import { openUserDetailModal } from "../../stores/modalStore";
import IsUserOnlineIcon from "../Icons/isUserOnlineIcon";

function OtherUserProfileButton({ user, size }) {
  const userId = user?.id;
  const userName = user?.username;
  const profileImage = user?.profile_image;
  const isUserOnline = user?.is_user_online;

  const handleProfileClick = () => {
    openUserDetailModal(userId);
  };

  if (!user) {
    return (
      <div>
        <div
          className="profile-icon loading-component"
          style={{ width: size, height: size, cursor: "default" }}
        ></div>
      </div>
    );
  }

  return (
    <div onClick={handleProfileClick} style={{ position: "relative" }}>
      <ProfileImage
        userName={userName}
        profileImage={profileImage}
        size={size} // for passing the size of the test
      />
      <IsUserOnlineIcon size={size} isUserOnline={isUserOnline} />
    </div>
  );
}

export default OtherUserProfileButton;

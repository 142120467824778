import React from "react";
import {
  restartTrack,
  playPreviousTrack,
  playNextTrack,
} from "../../stores/activeTrackStore.js";

let lastClickTime = 0;

// if previous track button is clicked twice within 500ms, play the previous track
const handlePreviousTrackClick = () => {
  const currentTime = new Date().getTime();
  const diffTime = currentTime - lastClickTime;

  if (diffTime < 500) {
    playPreviousTrack();
  } else {
    restartTrack();
  }

  lastClickTime = currentTime;
};

const handleNextTrackClick = () => {
  playNextTrack();
};

const PreviousTrackButton = () => {
  return (
    <div className="btn-seek" onClick={handlePreviousTrackClick}>
      <i className="bi bi-skip-backward-fill"></i>
    </div>
  );
};

const NextTrackButton = () => {
  return (
    <div className="btn-seek" onClick={handleNextTrackClick}>
      <i className="bi bi-skip-forward-fill"></i>
    </div>
  );
};

export { PreviousTrackButton, NextTrackButton };

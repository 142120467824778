// src/components/ReplyContainer/AttachmentObject.jsx

import React, { useState } from "react";
import PlayButton from "../Buttons/PlayButton.jsx";
import LoadingIndicatorCircle from "./LoadingIndicatorCircle.jsx";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LinkButton from "../Buttons/LinkButton.jsx";
import { handleLink } from "./LinkHandler.js";
import { handleDownload } from "./handleDownload.js";
import OptionsButton from "../Buttons/OptionsButton.jsx";

function AttachmentObject({ fileType, fileData, isInitiallyExpanded }) {
  // track variables
  const track = fileData;
  const trackId = track?.id;
  const trackFileName = track?.audio_file_name;
  const trackLink = track?.audio_file_original;

  // attachment variables
  const attachment = fileData;
  const attachmentId = attachment?.id;
  const attachmentFileName = attachment?.attachment_file_name;
  const attachmentLink = attachment?.attachment;

  // conditional variables
  const isTrack = fileType === "track";
  const fileId = isTrack ? trackId : attachmentId;
  const fileName = isTrack ? trackFileName : attachmentFileName;
  const fileLink = isTrack ? trackLink : attachmentLink;

  // state variables
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  // conditional classes
  const expandedClass = isExpanded ? "expanded" : "condensed";

  // Dropdown functions
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCondense = (event) => {
    // Because the handleExpand click is on the same div as the class condition, it will naturally propegate upward and reset the click state
    // Prevent the event from bubbling up to the parent
    event.stopPropagation();
    setIsExpanded(false);
  };

  const handleOpenInBrowser = () => {
    handleLink(fileLink);
  };

  const downloadFile = async () => {
    setDownloadIsLoading(true);
    try {
      await handleDownload({ isTrack, fileId, fileName });
    } finally {
      setDownloadIsLoading(false);
    }
  };

  return (
    <div
      className={`attachment-object ${expandedClass}`}
      onClick={handleExpand}
    >
      {isExpanded ? (
        isTrack ? (
          <PlayButton track={track} />
        ) : (
          <LinkButton link={fileLink} />
        )
      ) : fileType === "track" ? (
        <i className="bi bi-music-note"></i>
      ) : (
        <i className="bi bi-paperclip"></i>
      )}
      {isExpanded ? (
        <h5 className="text-overflow-trim short">{fileName}</h5>
      ) : (
        <p className="sm text-overflow-trim short">{fileName}</p>
      )}
      {isExpanded && !downloadIsLoading && (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle tag="div">
            <OptionsButton />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(event) => handleCondense(event)}>
              Condense
            </DropdownItem>
            <DropdownItem onClick={handleOpenInBrowser}>
              Open in browser
            </DropdownItem>
            <DropdownItem onClick={downloadFile}>Download</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {isExpanded && downloadIsLoading && (
        <div className="btn-circle-sm">
          <LoadingIndicatorCircle />
        </div>
      )}
    </div>
  );
}

export default AttachmentObject;

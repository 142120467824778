import React from "react";
import { openUserDetailModal } from "../../stores/modalStore";
import { usePostNavigation } from "../../hooks/navigationHooks";
import { collectiveIdStore } from "../../stores/collectiveDetailStore";

// function to check if the JSON object is valid
const isValidJSON = (json) => {
  if (!json || typeof json !== "object" || Array.isArray(json)) {
    return false;
  }

  if (!json.root || typeof json.root !== "object" || Array.isArray(json.root)) {
    return false;
  }

  if (!Array.isArray(json.root.children)) {
    return false;
  }

  // Add more checks if needed

  return true;
};

const JSONtoHTMLComponent = ({ json, text }) => {
  const navigateToPost = usePostNavigation();

  // check for valid JSON object
  if (!isValidJSON(json)) {
    return (
      <div className="text-break">
        <p>{text}</p>
      </div>
    );
  }

  const handleUserClick = (userId, e) => {
    e.preventDefault();
    openUserDetailModal(userId);
  };

  const handlePostClick = (postId, e) => {
    e.preventDefault();
    const activeCollectiveId = collectiveIdStore.get();
    navigateToPost(activeCollectiveId, postId, postId);
  };

  // // handle hover events
  // const handleUserHover = (e) => {
  //   e.preventDefault();
  //   console.log("User hovered");
  // };

  // const handlePostHover = (e) => {
  //   e.preventDefault();
  //   console.log("Post hovered");
  // };

  const parseJSON = (data, parentIndex = 0) => {
    if (data.type === "root") {
      return data.children.map((child, index) => parseJSON(child, index));
      // handle paragraphs
    } else if (data.type === "paragraph") {
      if (data.children.length === 0) {
        // If the paragraph is empty, render a <br /> element
        return <br key={`empty-paragraph-${parentIndex}-${data.version}`} />;
      }
      return (
        <p
          key={`paragraph-${parentIndex}-${data.version}`}
          style={{ textAlign: data.direction }}
        >
          {data.children.map((child, index) => parseJSON(child, index))}
        </p>
      );
      // handle strong text
    } else if (data.type === "text" && data.format === 1) {
      return (
        <strong key={`text-${parentIndex}-${data.version}`}>{data.text}</strong>
      );
      // handle italic text
    } else if (data.type === "text" && data.format === 2) {
      return <em key={`text-${parentIndex}-${data.version}`}>{data.text}</em>;
      // handle plain text
    } else if (data.type === "text") {
      return (
        <span key={`text-${parentIndex}-${data.version}`}>{data.text}</span>
      );
      // handle beautifulMentions users
    } else if (data.type === "beautifulMention" && data.trigger === "@") {
      const userId = data?.data?.id;
      return (
        <span
          key={`mention-${parentIndex}-${data.version}`}
          className="rte-mention user"
          onClick={(e) => handleUserClick(userId, e)}
          // onMouseOver={handleUserHover}
        >
          {data.trigger}
          {data.value}
        </span>
      );
      // handle beautifulMentions posts
    } else if (data.type === "beautifulMention" && data.trigger === "#") {
      const postId = data?.data?.id;
      return (
        <span
          key={`mention-${parentIndex}-${data.version}`}
          className="rte-mention"
          onClick={(e) => handlePostClick(postId, e)}
          // onMouseOver={handlePostHover}
        >
          {data.trigger}
          {data.value}
        </span>
      );
      // handle other beautifulMentions
    } else if (data.type === "beautifulMention") {
      console.log(data);
      return (
        <span
          key={`mention-${parentIndex}-${data.version}`}
          className="rte-mention"
        >
          {data.trigger}
          {data.value}
        </span>
      );
    }
    return null;
  };

  return <div>{parseJSON(json.root)}</div>;
};

export default JSONtoHTMLComponent;

// Utility function for getting data from localStorage
export function getFromStorage(key, defaultValue = null) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
}

// Utility function for setting data to localStorage
export function setToStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// src/services/Notifications/listNotifications.js

import axiosInstance from "../Utils/axiosInstance";

const listNotifications = async () => {
  try {
    const response = await axiosInstance.get(`/api/notifications/list`);
    return response.data;
  } catch (error) {}
};

export { listNotifications };

import moment from "moment";

export const formatAudioTimestamp = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const formatPostTimestamp = (timestamp) => {
  return moment(timestamp).fromNow();
};

import React, { useRef, useEffect } from "react";

const TextInputField = ({
  fieldName,
  initialValue,
  setValue,
  isLoading,
  placeholder,
}) => {
  const divRef = useRef();

  // set the content of the div to the initial value when the component mounts
  useEffect(() => {
    if (divRef.current) {
      divRef.current.textContent = initialValue;
    }
  }, [initialValue]);

  // input change handler
  const handleInput = (e) => {
    setValue(e.currentTarget.textContent);
  };

  if (setValue === undefined) {
    return (
      <div>
        {fieldName && (
          <div className="new-post-form-input-fieldname text-prevent-selecting">
            {fieldName}
          </div>
        )}
        <div
          className="new-post-form-input text-muted"
          style={{ cursor: "text" }}
        >
          {initialValue}
        </div>
      </div>
    );
  }

  return (
    <div>
      {fieldName && (
        <div className="new-post-form-input-fieldname text-prevent-selecting">
          {fieldName}
        </div>
      )}
      <div
        ref={divRef}
        contentEditable={!isLoading}
        className={`new-post-form-input ${isLoading ? "loading-field" : ""}`}
        placeholder={placeholder}
        onInput={handleInput}
        suppressContentEditableWarning={true}
      />
    </div>
  );
};

export default TextInputField;

import React from "react";
import BaseModal from "./BaseModal.jsx";
import { useStore } from "@nanostores/react";
import {
  createProjectModalOpenStore,
  editProjectModalOpenStore,
} from "../../stores/modalStore.js";
import CreateProjectForm from "../Forms/CreateProjectForm.jsx";

function CreateProjectModal() {
  const showCreateModal = useStore(createProjectModalOpenStore);
  const showEditModal = useStore(editProjectModalOpenStore);
  const showModal = showCreateModal || showEditModal;
  const modalTitle = showCreateModal ? "Create a Project" : "Edit Project";

  return (
    <>
      <BaseModal
        modalTitle={modalTitle}
        showModal={showModal}
        isLoading={false}
        toolTipText={
          "A Project is a space within a Collective for members to organize their music and posts around a specific theme or idea."
        }
      >
        <div className="modal-form">
          <CreateProjectForm />
        </div>
      </BaseModal>
    </>
  );
}

export default CreateProjectModal;

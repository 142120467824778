//src/components/PostDetail/PostDetail.jsx

import React from "react";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton.jsx";
const PostDetailLoading = () => {
  return (
    <div
      className="detail-area"
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <div className="track-container">
        <div className="track-details">
          <div>
            <OtherUserProfileButton size="2.5rem" />
          </div>
          <div className="track-info" style={{ gap: ".5rem" }}>
            <div className="loading-component bar width-sm"></div>
            <div className="loading-component bar width-md"></div>
          </div>
        </div>
        <div className="loading-component bar top-margin width-lg"></div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginLeft: "2rem",
            width: "15rem",
          }}
        >
          <div className="track-container">
            <div className="track-details">
              <div>
                <OtherUserProfileButton size="2rem" />
              </div>
              <div className="track-info" style={{ gap: ".5rem" }}>
                <div className="loading-component bar width-lg"></div>
                <div className="loading-component bar width"></div>
              </div>
            </div>
          </div>
          <div className="track-container">
            <div className="track-details">
              <div>
                <OtherUserProfileButton size="2rem" />
              </div>
              <div className="track-info" style={{ gap: ".5rem" }}>
                <div className="loading-component bar width-lg"></div>
                <div className="loading-component bar width"></div>
              </div>
            </div>
          </div>
          <div className="track-container">
            <div className="track-details">
              <div>
                <OtherUserProfileButton size="2rem" />
              </div>
              <div className="track-info" style={{ gap: ".5rem" }}>
                <div className="loading-component bar width-lg"></div>
                <div className="loading-component bar width"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailLoading;

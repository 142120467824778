// src/services/Users/currentUserDetails.js

import axiosInstance from "../Utils/axiosInstance"; // Adjust the path as needed

const getCurrentUserDetails = async () => {
  try {
    const response = await axiosInstance.get("/api/users/current_user_details");
    return response.data;
  } catch (error) {}
};

export { getCurrentUserDetails };

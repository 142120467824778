// src/services/Users/retrieveUser.js

import axiosInstance from "../Utils/axiosInstance";

const retrieveUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/users/${userId}/retrieve`);
    return response.data;
  } catch (error) {}
};

export { retrieveUser };

import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CloseButton from "../Buttons/CloseButton";
import { useStore } from "@nanostores/react";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore";
import TooltipIcon from "../Icons/TooltipIcon";

function BaseModal({ modalTitle, showModal, children, toolTipText }) {
  const isModalLoading = useStore(isModalLoadingStore);
  const canClose = isModalLoading ? false : true;

  const closeModal = () => {
    if (canClose) {
      closeModals();
    }
  };

  return (
    <>
      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader tag="h1">
          {modalTitle}
          {canClose && (
            <div onClick={closeModal}>
              <CloseButton />
            </div>
          )}
          {toolTipText && (
            <TooltipIcon id="tooltip" toolTipText={toolTipText} />
          )}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
}

export default BaseModal;

// src/services/Users/currentUser.js

import axiosInstance from "../Utils/axiosInstance";

const getChildPosts = async (postId) => {
  try {
    const response = await axiosInstance.get(
      `/api/posts/${postId}/get_first_children`,
    );
    return response.data;
  } catch (error) {}
};

export { getChildPosts };

// src/stores/currentUserStore.js

import { atom } from "nanostores";
import { getCurrentUser } from "../services/Users/currentUser";
import { getFromStorage, setToStorage } from "./storageUtils";

// Set initial state from local storate to preserve on page refresh
export const currentUserStore = atom(getFromStorage("currentUserDetails"));
export const currentUserIdStore = atom(currentUserStore.get()?.id || null);
export const currentUserNameStore = atom(
  currentUserStore.get()?.username || null,
);
export const currentUserProfileImageStore = atom(
  currentUserStore.get()?.profile_image || null,
);
export const currentUserDefaultCollectiveIdStore = atom(
  currentUserStore.get()?.default_collective_id || null,
);
export const currentUserLoadingStore = atom(
  getFromStorage("userLoading", false),
);
export const currentUserErrorStore = atom(null);

export async function fetchCurrentUser() {
  currentUserLoadingStore.set(true);
  try {
    const currentUserDetails = await getCurrentUser();
    currentUserStore.set(currentUserDetails);
    currentUserIdStore.set(currentUserDetails.id);
    currentUserNameStore.set(currentUserDetails.username);
    currentUserProfileImageStore.set(currentUserDetails.profile_image);
    currentUserDefaultCollectiveIdStore.set(
      currentUserDetails.default_collective_id,
    );
    currentUserLoadingStore.set(false);
    setToStorage("currentUserDetails", currentUserDetails);
    setToStorage("userLoading", false);
  } catch (error) {
    currentUserErrorStore.set(error);
    currentUserLoadingStore.set(false);
  }
}

// src/components/ReplyContainer/ProfileImageContainer.jsx

import React from "react";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton";

const ThreadLine = () => (
  <div
    style={{
      height: "100%", // cover the height of the container
      borderLeft: "1px solid var(--border-color)", // vertical reply line
      borderBottom: "1px solid var(--border-color)", // curve of reply line
      borderRadius: "0 0 0 .8rem", // a curve to the line
      position: "absolute", // allows multiple overlapping lines within the container
      left: 0, // Align to the left of the parent
      top: 0, // Align to the top of the parent
      width: "100%", // cover the width so it shows the curve line
    }}
  ></div>
);

const ThreadContainer = () => (
  <div
    style={{
      height: "100%",
      width: "50%", // centers the line
      marginTop: ".5rem",
      marginLeft: "auto", // position the border line center
      position: "relative", // Needed to position children absolutely
      marginBottom: "3.7rem", // align the bottom of the container roughly at the middle of the profile image of the reply form
    }}
  >
    <div>
      <ThreadLine />
    </div>
  </div>
);

function ProfileImageContainer({ user, isReplyExpanded }) {
  return (
    <div
      style={{
        height: "100%",

        display: "flex",
        flexDirection: "column",
        marginRight: ".5rem",
      }}
    >
      <OtherUserProfileButton user={user} size={"1.8rem"} />
      {isReplyExpanded && <ThreadContainer />}
    </div>
  );
}

export default ProfileImageContainer;

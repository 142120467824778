import React, { useState } from "react";
import OptionsButton from "../Buttons/OptionsButton";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  markAllNotificationsAsRead,
  markAllNotificationsAsUnread,
} from "../../services/Notifications/markNotifications";

const handleMarkAllAsRead = async () => {
  await markAllNotificationsAsRead();
  window.location.reload();
};

const handleMarkAllAsUnread = async () => {
  await markAllNotificationsAsUnread();
  window.location.reload();
};

const OptionsDropdown = () => {
  // Dropdown functions
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      style={{ position: "absolute", top: "0", right: "0", padding: ".5rem" }}
    >
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="left">
        <DropdownToggle tag="div">
          <OptionsButton />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleMarkAllAsRead}>
            Mark all as read
          </DropdownItem>
          <DropdownItem onClick={handleMarkAllAsUnread}>
            Mark all as unread
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default OptionsDropdown;

// src/stores/activeTrackStore.js

import { atom } from "nanostores";
import { webAudioManager } from "../components/AudioPlayer/WebAudioManager";
import { getPlaylistTracks } from "../services/Tracks/playlistTracks";
import { projectIdStore } from "./collectiveDetailStore";

export const activeTrackStore = atom(null);
export const activeTrackIsPlayingStore = atom(false);
export const activeTrackDurationStore = atom(null);
export const activeTrackCurrentTimeStore = atom(null);
export const previousTrackStore = atom(null);
export const nextTrackStore = atom(null);

let currentTimeInterval = null;

export function playPauseTrack(track) {
  const currentTrackId = track?.id;
  const activeTrackId = activeTrackStore.get()?.id;
  const activeTrackIsPlaying = activeTrackIsPlayingStore.get();

  if (currentTrackId === activeTrackId && activeTrackIsPlaying) {
    pauseTrack();
  } else if (currentTrackId === activeTrackId && !activeTrackIsPlaying) {
    playTrack();
  } else {
    setNewTrack(track);
    playTrack();
  }
}

export function setNewTrack(track) {
  // Reset the current time for the new track to avoid a flash of the previous track's waveform
  setTrackCurrentTime(0);
  // set the audio URL for the WebAudioManager
  const audioUrl = track?.audio_file_aac;
  // set track details for the WebAudioManager metadata
  const trackDetails = {
    title: track.audio_file_name,
    username: track.user.username,
    profile_image: track.user.profile_image,
  };
  webAudioManager.setTrack(audioUrl, trackDetails);
  activeTrackStore.set(track);
  if (track) {
    const activeProjectId = projectIdStore.get();
    fetchPlaylistTracks(track.id, activeProjectId);
  }
}

export function playTrack() {
  webAudioManager.play(() => {
    activeTrackIsPlayingStore.set(true);
    startUpdatingCurrentTime();
    webAudioManager.audio.onloadedmetadata = () => {
      setTrackDuration(webAudioManager.audio.duration);
    };
  });
}

export function pauseTrack() {
  webAudioManager.pause(() => {
    activeTrackIsPlayingStore.set(false);
    stopUpdatingCurrentTime();
  });
}

export function restartTrack() {
  updateTrackCurrentTime(activeTrackStore.get(), 0);
}

export function playPreviousTrack() {
  const previousTrack = previousTrackStore.get();
  if (previousTrack !== null) {
    setNewTrack(previousTrack);
    playTrack();
  }
}

export function playNextTrack() {
  const nextTrack = nextTrackStore.get();
  if (nextTrack !== null) {
    setNewTrack(nextTrack);
    playTrack();
  }
}

export function setTrackDuration(duration) {
  activeTrackDurationStore.set(duration);
}

function startUpdatingCurrentTime() {
  if (currentTimeInterval !== null) {
    clearInterval(currentTimeInterval);
  }
  currentTimeInterval = setInterval(() => {
    const currentTime = webAudioManager.getCurrentTime();
    setTrackCurrentTime(currentTime);
  }, 400); // Interval to update the track duration
}

function stopUpdatingCurrentTime() {
  if (currentTimeInterval !== null) {
    clearInterval(currentTimeInterval);
    currentTimeInterval = null;
  }
}

export function setTrackCurrentTime(currentTime) {
  activeTrackCurrentTimeStore.set(currentTime);
}

export function updateTrackCurrentTime(track, newTime) {
  const currentTrack = track;
  const currentTrackId = currentTrack?.id;
  const activeTrackId = activeTrackStore.get()?.id;

  if (currentTrackId !== activeTrackId) {
    setNewTrack(track);
    playTrack();
  }

  // Set the new current time in the store
  setTrackCurrentTime(newTime);
  // Seek the WebAudioManager's audio element to the new current time
  webAudioManager.seekTo(newTime);
}

export async function fetchPlaylistTracks(trackId, projectId) {
  try {
    // Reset the previous and next track while results are being fetched
    nextTrackStore.set(null);
    previousTrackStore.set(null);
    // Fetch the playlist tracks for the current track
    const playlistTracks = await getPlaylistTracks(trackId, projectId);
    const previousTrack = playlistTracks?.previous_track;
    const nextTrack = playlistTracks?.next_track;
    // Set the previous and next track in the store
    previousTrackStore.set(previousTrack);
    nextTrackStore.set(nextTrack);
  } catch (error) {
    console.error("Error fetching playlist tracks:", error);
  }
}

// src/components/Buttons/PlayButton.jsx

import React from "react";
import LoadingIndicatorCircle from "../Utils/LoadingIndicatorCircle.jsx";
import { useStore } from "@nanostores/react";
import {
  activeTrackStore,
  activeTrackIsPlayingStore,
  playPauseTrack,
} from "../../stores/activeTrackStore.js";

const PlayButton = ({ track }) => {
  const currentTrackId = track?.id;
  const currentTrackAudioUrl = track?.audio_file_aac;
  const activeTrack = useStore(activeTrackStore);
  const activeTrackId = activeTrack?.id;
  const activeTrackIsPlaying = useStore(activeTrackIsPlayingStore);
  // check if the current track is the active track
  const currentTrackIsActive = activeTrackId === currentTrackId;

  const handlePlayPause = () => {
    playPauseTrack(track);
  };

  // if audioUrl is empty, show loading indicator
  if (!currentTrackAudioUrl) {
    return (
      <div className="btn-circle-md">
        <LoadingIndicatorCircle />
      </div>
    );
  }

  // if the current track is not active, show play button
  if (!currentTrackIsActive) {
    return (
      <div className="btn-circle-md btn-play" onClick={handlePlayPause}>
        <div>
          <span className="btn-circle-lg-icon play-button-icon">
            <i className="bi bi-play-fill"></i>
          </span>
        </div>
      </div>
    );
  }

  // if the current track is active and playing, show pause button
  if (currentTrackIsActive && activeTrackIsPlaying) {
    return (
      <div className="btn-circle-md btn-pause" onClick={handlePlayPause}>
        <div>
          <span className="btn-circle-lg-icon">
            <i className="bi bi-pause-fill"></i>
          </span>
        </div>
      </div>
    );
  }

  // if the current track is active but not playing, show play button
  if (currentTrackIsActive && !activeTrackIsPlaying) {
    return (
      <div className="btn-circle-md btn-play-active" onClick={handlePlayPause}>
        <div>
          <span className="btn-circle-lg-icon play-button-icon">
            <i className="bi bi-play-fill"></i>
          </span>
        </div>
      </div>
    );
  }
};

export default PlayButton;

import React from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer.jsx";
import { useStore } from "@nanostores/react";
import { activeTrackStore } from "../../stores/activeTrackStore.js";
import PublicFooter from "./PublicFooter.jsx";

const ProtectedFooter = () => {
  const activeTrack = useStore(activeTrackStore);
  const trackIsActive = activeTrack !== null;

  return <div>{trackIsActive ? <AudioPlayer /> : <PublicFooter />}</div>;
};

export default ProtectedFooter;

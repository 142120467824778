// src/services/Collectives/editCollective.js

import axiosInstance from "../Utils/axiosInstance";

const editCollective = async (
  collectiveId,
  collectiveTitle,
  collectiveDescription,
  collectiveImage,
) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";
  let editedCollectiveId = null;

  const url = `/api/collectives/${collectiveId}/edit_collective/`;

  // call the API
  try {
    // Create a new FormData object
    const formData = new FormData();

    formData.append("title", collectiveTitle);
    formData.append("description", collectiveDescription);

    // append image only if it is a file
    // this is to prevent the backend from trying to handle a url string
    if (collectiveImage instanceof File) {
      formData.append("collective_image", collectiveImage);
    }

    // if image is null, append a null value to the form data
    // this is to handle instances where the user wants to remove the image
    if (collectiveImage === null) {
      formData.append("collective_image", "");
    }

    const response = await axiosInstance.patch(url, formData);
    successMessage = "Collective edited successfully";
    editedCollectiveId = response.data.id;
  } catch (error) {
    console.log(error);
    if (error.response.data.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, warningMessage, dangerMessage, editedCollectiveId };
};

export { editCollective };

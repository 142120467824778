import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TinyDropdownIcon from "../Icons/TinyDropdownIcon";
import ProfileImage from "../Utils/ProfileImage";
import logOut from "../../services/Auth/logOut";
import { useStore } from "@nanostores/react";
import {
  currentUserIdStore,
  currentUserNameStore,
  currentUserProfileImageStore,
  currentUserLoadingStore,
  currentUserErrorStore,
} from "../../stores/currentUserStore";
import { collectiveListStore } from "../../stores/collectiveDetailStore";
import {
  inviteUserModalOpenStore,
  openUserDetailModal,
  editUserProfileModalOpenStore,
} from "../../stores/modalStore";

function CurrentUserProfileButton() {
  const userName = useStore(currentUserNameStore);
  const profileImage = useStore(currentUserProfileImageStore);
  const loading = useStore(currentUserLoadingStore);
  const error = useStore(currentUserErrorStore);
  const collectiveList = useStore(collectiveListStore);
  const currentUserId = useStore(currentUserIdStore);

  // Dropdown functions
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleLogOut = () => {
    logOut();
  };

  const showInviteNewUserModal = () => {
    inviteUserModalOpenStore.set(true);
  };

  const handleProfileClick = () => {
    openUserDetailModal(currentUserId);
  };

  const handleEditProfileClick = () => {
    editUserProfileModalOpenStore.set(true);
  };

  if (loading || error)
    return (
      <div>
        <button className="profile-icon"></button>
      </div>
    );

  if (!loading)
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle tag="div" className="profile-icon">
          <ProfileImage
            userName={userName}
            profileImage={profileImage}
            size={"2.5rem"}
          />
          <TinyDropdownIcon />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleProfileClick}>
            View My Profile
          </DropdownItem>
          <DropdownItem onClick={handleEditProfileClick}>
            Edit My Profile
          </DropdownItem>
          <DropdownItem onClick={showInviteNewUserModal}>
            Invite New User
          </DropdownItem>
          {collectiveList && collectiveList.length > 0 && (
            <>
              <DropdownItem divider />
              <DropdownItem header>My Collectives</DropdownItem>
              {collectiveList.map((collective) => (
                <DropdownItem
                  key={collective.id}
                  tag={Link}
                  to={`/studio/collective/${collective.id}`}
                  style={{ paddingLeft: "2rem" }}
                  className="text-wrap-1"
                >
                  {collective.title}
                </DropdownItem>
              ))}
            </>
          )}
          <DropdownItem divider />
          <DropdownItem onClick={handleLogOut}>Log out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );

  return null; // Fallback in case there's no data
}

export default CurrentUserProfileButton;

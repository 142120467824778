// src/services/Collectives/listCollectives.js

import axiosInstance from "../Utils/axiosInstance";

const listCollectives = async () => {
  // Define the search query parts
  const url = `/api/collectives/list_user_collectives/`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    // silently catch the error
  }
};

export { listCollectives };

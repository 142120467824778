import React, { useRef, useState, useEffect } from "react";

const EditableImageContainer = ({ children, image, setImage }) => {
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
    }
  }, []);

  // Dynamically set the height of the edit icon
  const editIconHeight = `${containerHeight * 0.3}px`;

  const handleAddImageClick = () => {
    // Trigger file input when the container is clicked
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Optionally, validate file type here or set the accepted types via input element

      // Call setImage prop to pass the file up to the parent component
      setImage(file);
    }
  };

  return (
    <>
      <div
        className="editable-image-container"
        ref={containerRef}
        onClick={handleAddImageClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/jpeg, image/png, image/jpg"
          onChange={handleFileChange}
        />
        <div className="editable-image-container-icon">
          <i style={{ fontSize: editIconHeight }} className="bi bi-pencil"></i>
        </div>
        <div className="editable-image-container-content">{children}</div>
      </div>
      {image && (
        <span
          className="editable-image-remove-icon"
          onClick={handleRemoveImage}
        >
          remove image
        </span>
      )}
    </>
  );
};

export default EditableImageContainer;

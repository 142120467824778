// src/pages/CollectivePage.jsx

import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import DetailArea from "../components/DetailArea/DetailArea";
import {
  collectiveIdStore,
  fetchCollectiveDetails,
  clearCollectiveDetailsStore,
} from "../stores/collectiveDetailStore";
import {
  setActivePostId,
  fetchParentTree,
  clearPostStore,
} from "../stores/postDetailStore";
import { currentUserDefaultCollectiveIdStore } from "../stores/currentUserStore";
import { useCollectiveNavigation } from "../hooks/navigationHooks";

const CollectivePage = () => {
  const navigateToCollective = useCollectiveNavigation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const collectiveId = useParams().collectiveId;
  const projectId = searchParams.get("projectId");
  const postId = searchParams.get("postId");
  const childPostId = searchParams.get("childPostId");
  const defaultCollectiveId = currentUserDefaultCollectiveIdStore.get();

  useEffect(() => {
    // Check if the current path does not contain a Collective id
    if (collectiveId === undefined) {
      // Redirect to the default Collective if it exists
      if (defaultCollectiveId) {
        navigateToCollective(defaultCollectiveId, { replace: true });
        // otherwise redirect to Collective 5 (MCS community)
      } else {
        navigateToCollective(5, { replace: true });
      }
    } else {
      // once the above logic has been handled the collectiveId should then be valid within the url
      // this should provide a valid collectiveId to fetch the Collective details
      // therefore, Collective details will only be fetched once as the above logic redirects to a valid url before fetch is called
      // think of the above code as a safety net before it gets to this point in the script

      // if the collectiveId is different from the one in the store, clear the store
      // make sure that collectiveId is a number
      if (Number(collectiveId) !== collectiveIdStore.get()) {
        clearCollectiveDetailsStore();
        clearPostStore();
      }

      // Fetch Collective details with the post ID and set active post
      // Use the projectId from the url if it exists
      // backend will handle the case where projectId is not provided
      fetchCollectiveDetails(collectiveId, projectId).then(() => {
        // if post id is in the url then set active post
        if (postId) {
          setActivePostId(postId);
          // if child post id is in the url then fetch parent tree
          // fetch parent tree will also set the ids that should be rendered
          if (childPostId) {
            fetchParentTree(childPostId);
          }
        }
      });
    }
  }, [
    collectiveId,
    projectId,
    postId,
    childPostId,
    defaultCollectiveId,
    navigateToCollective,
  ]);

  return (
    <div className="protected-page-layout">
      <SideBar />
      <DetailArea />
    </div>
  );
};

export default CollectivePage;

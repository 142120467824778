//src/pages/HomePage.jsx

import React, { useEffect, useState } from "react";
import MCSLogoText from "../assets/images/logo-text.png";
import RegisterForm from "../components/Forms/RegisterForm";
import RequestAccessForm from "../components/Forms/RequestAccessForm";
import HorizontalDivider from "../components/Utils/HorizontalDivider";
// import useScrollToElement from "../hooks/useScrollToElement";
import { useLocation } from "react-router-dom";
import "../styles/home-page.css";

const RegisterPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [inviteCode] = useState(queryParams.get("invite-code"));
  // // custom hook to scroll to the element from the url when it is rendered
  // useScrollToElement();

  useEffect(() => {
    const adjustCardHeights = () => {
      const cards = document.querySelectorAll(".feature-card");
      let maxHeight = 0;

      cards.forEach((card) => (card.style.height = "auto")); // Reset heights
      cards.forEach((card) => {
        if (card.offsetHeight > maxHeight) {
          maxHeight = card.offsetHeight;
        }
      });
      cards.forEach((card) => (card.style.height = `${maxHeight}px`)); // Apply max height
    };

    adjustCardHeights(); // Adjust heights on load

    const handleResize = () => adjustCardHeights();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  return (
    <div className="main-layout">
      <div className="container">
        <div className="page">
          {/* Header section with register form  */}
          <div className="register-page-section-1">
            <h1>Join the MCS Community</h1>
            <div
              className="logo-container"
              style={{
                maxWidth: "10rem",
                height: "auto",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <a href="/">
                <img src={MCSLogoText} alt="MCS logo with text" />
              </a>
            </div>
            {!inviteCode && (
              <>
                <p className="xl fadeInRight">
                  Begin your next creative journey by{" "}
                  <strong>requesting access</strong>
                </p>
                <div className="register-form fadeInRight" id="login">
                  <RequestAccessForm />
                </div>
                <p className="xl fadeInLeft">
                  Or use an <strong>invite code</strong> provided by an existing
                  member
                </p>
              </>
            )}
            <div className="register-form fadeInLeft" id="login">
              <RegisterForm inviteCode={inviteCode} />
            </div>
          </div>
          <div style={{ width: "15rem", margin: "0 auto" }}>
            <HorizontalDivider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;

import React from "react";
import BaseModal from "./BaseModal.jsx";
import { useStore } from "@nanostores/react";
import {
  createCollectiveModalOpenStore,
  editCollectiveModalOpenStore,
} from "../../stores/modalStore.js";
import CreateCollectiveForm from "../Forms/CreateCollectiveForm.jsx";

function CreateCollectiveModal() {
  const showCreateModal = useStore(createCollectiveModalOpenStore);
  const showEditModal = useStore(editCollectiveModalOpenStore);
  const showModal = showCreateModal || showEditModal;
  const modalTitle = showCreateModal
    ? "Create a Collective"
    : "Edit Collective";

  return (
    <>
      <BaseModal
        modalTitle={modalTitle}
        showModal={showModal}
        isLoading={false}
        toolTipText={
          "A Collective is a space for an Artist, Band, or group of musicians to share their music and collaborate."
        }
      >
        <div className="modal-form">
          <CreateCollectiveForm />
        </div>
      </BaseModal>
    </>
  );
}

export default CreateCollectiveModal;

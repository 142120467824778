// src/services/Collectives/createCollective.js

import axiosInstance from "../Utils/axiosInstance";

const createCollective = async (
  collectiveTitle,
  collectiveDescription,
  collectiveImage,
) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";
  let newCollectiveId = null;

  // call the API
  try {
    // Create a new FormData object
    const formData = new FormData();

    formData.append("title", collectiveTitle);
    formData.append("description", collectiveDescription);
    formData.append("collective_image", collectiveImage);

    const response = await axiosInstance.post(
      "/api/collectives/create_collective/",
      formData,
    );
    successMessage = "Collective created successfully";
    newCollectiveId = response.data.id;
  } catch (error) {
    console.log(error);
    if (error.response.data.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, warningMessage, dangerMessage, newCollectiveId };
};

export { createCollective };

//src/components/Utils/Alerts.jsx

import React from "react";

export const SuccessAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="success-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export const WarningAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="warning-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export const DangerAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="danger-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

import React from "react";

function TinyDropdownIcon() {
  return (
    <span className="tiny-dropdown-icon">
      <i className="bi bi-chevron-down"></i>
    </span>
  );
}

export default TinyDropdownIcon;

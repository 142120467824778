//src/layouts/ProtectedLayout.jsx

import React from "react";
import MCSLogoText from "../assets/images/logo-text.png";
import HorizontalDivider from "../components/Utils/HorizontalDivider";

const DefaultPage = () => {
  return (
    <div className="main-layout">
      <div className="container">
        <div className="page">
          <div className="register-page-section-1">
            <h1>The page you're looking for could not be found.</h1>
          </div>
          <div style={{ width: "15rem", margin: "0 auto" }}>
            <HorizontalDivider />
          </div>
          <div className="button-container fadeInRight">
            <div className="logo-container">
              <a href="/">
                <img src={MCSLogoText} alt="MCS logo with text" />
              </a>
            </div>
            <button
              className="btn-primary"
              onClick={() => (window.location.href = "/")}
            >
              <h2>Log In</h2>
            </button>
            <button
              className="btn-outline"
              onClick={() => (window.location.href = "/")}
            >
              <h2>Learn More</h2>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultPage;

// src/services/UserInvite/requestInvite.js

import axiosInstance from "../Utils/axiosInstance";

const requestInvite = async (email) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";

  // validate email is not blank
  if (email === "") {
    warningMessage = "Please enter your email address.";
    return { successMessage, warningMessage, dangerMessage };
  }

  const successMessageText = `<p>Thank you for your interest in joining the community!</p>
  <p>Due to high demand, we are currently reviewing all requests to join our platform. We will notify you as soon as your request has been approved.</p>
  <p>Redirecting you to the home page...</p>`;

  try {
    await axiosInstance.post(`/api/user-invites/request_invite/`, {
      recipient_email: email,
    });
    successMessage = successMessageText;
  } catch (error) {
    successMessage = successMessageText;
    // silently catch the error to prevent leaking user information
  }

  return { successMessage, warningMessage, dangerMessage };
};

export { requestInvite };

//src/components/DetailArea/CollectiveDetail/Components/PendingInvitesCard.jsx

import React, { useState } from "react";
import { Table } from "reactstrap";
import { Card, CardRow } from "../../../Utils/CustomCard";
import OtherUserProfileButton from "../../../Buttons/OtherUserProfileButton";

function PendingInvitesCard({ title, pendingInvites, isLoading, isHidden }) {
  // index for managing the copied link states
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (index, inviteUrl) => {
    navigator.clipboard.writeText(inviteUrl).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 3000); // Reset after 2 seconds
    });
  };

  if (pendingInvites?.length > 0 && !isHidden) {
    return (
      <Card title={title} isLoading={isLoading}>
        <CardRow>
          <Table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Invite Link</th>
              </tr>
            </thead>
            <tbody>
              {pendingInvites?.map((pendingInvite, index) => (
                <tr key={index}>
                  <td className="table-cell">
                    <OtherUserProfileButton size="1.8rem" />
                    <span className="text-wrap-1">{pendingInvite?.email}</span>
                  </td>
                  <td style={{ width: "10rem", textAlign: "center" }}>
                    {pendingInvite?.invite_url && (
                      <div
                        onClick={() =>
                          handleCopy(index, pendingInvite?.invite_url)
                        }
                      >
                        <div
                          className={`${copiedIndex === index ? "btn-outline md" : "btn-black md"}`}
                        >
                          <p className="md">
                            {copiedIndex === index
                              ? "Link Copied"
                              : "Copy Invite Link"}
                          </p>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardRow>
      </Card>
    );
  }
}

export default PendingInvitesCard;

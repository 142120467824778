import React, { useState, useEffect, useRef } from "react";
import { searchUsers } from "../../../services/Users/searchUsers";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton";
import { inviteUserModalOpenStore } from "../../../stores/modalStore";
import LoadingIndicatorBars from "../../Utils/LoadingIndicatorBars";

const SearchField = ({ isLoading, selectedUsers, setSelectedUsers }) => {
  // state values
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(null);

  // reference to ensure that the search results are for the latest search term
  const latestSearchValueRef = useRef("");

  // get the data from the response
  const searchResultUsers = searchResults?.results || [];
  const searchResultsNextPageUrl = searchResults?.next || null;

  // fetch search results when the search value changes
  useEffect(() => {
    setIsLoadingSearchResults(true);
    setSearchResults([]);
    latestSearchValueRef.current = searchValue;

    const fetchData = async () => {
      if (searchValue.length > 0) {
        const response = await searchUsers(searchValue);
        // Only update state if this response is for the latest search term
        // This prevents the search results from being overwritten from a previous search value
        // For example, if typing quickly, the search results for "a" should not overwrite the search results for "ab"
        if (latestSearchValueRef.current === searchValue) {
          setSearchResults(response);
          setIsLoadingSearchResults(false);
        }
      } else {
        setIsLoadingSearchResults(false);
      }
    };

    fetchData();
  }, [searchValue]);

  // handle search value change
  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  // open the invite user modal - this will be visible when there are no search results
  const handleInviteUser = () => {
    inviteUserModalOpenStore.set(true);
  };

  // load more search results
  const handleLoadMoreResults = async () => {
    setIsLoadingSearchResults(true);
    try {
      const response = await searchUsers(searchValue, searchResultsNextPageUrl);
      setSearchResults((prevResults) => ({
        ...response,
        results: [...prevResults.results, ...response.results],
      }));
    } catch (error) {
      setIsLoadingSearchResults(false);
    } finally {
      setIsLoadingSearchResults(false);
    }
  };

  // handle the selection of users in the search results
  const handleUserSelection = (user, e) => {
    if (e.target.checked) {
      setSelectedUsers((prevUsers) => [...prevUsers, user]);
    } else {
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((u) => u.id !== user.id),
      );
    }
  };

  // render the selected users
  const SelectedResults = () => {
    // if there are no selected users, do not render anything
    if (selectedUsers.length === 0) {
      return null;
    }
    return (
      <div className="form-search-results">
        {selectedUsers.map((user) => (
          <div key={user.id} className="form-search-result-item">
            <input
              type="checkbox"
              checked={selectedUsers.some(
                (selectedUser) => selectedUser.id === user.id,
              )}
              onChange={(e) => handleUserSelection(user, e)}
            />
            <OtherUserProfileButton user={user} size="1.8rem" />
            <h4>{user.username}</h4>
          </div>
        ))}
      </div>
    );
  };

  // conditionally render the search results
  const SearchResults = () => {
    // if the search value is empty, do not render the search results
    if (searchValue === "") {
      return null;
    }

    // filter out selected users as they show in the selected results
    const unselectedUsers = searchResultUsers.filter(
      (user) =>
        !selectedUsers.some((selectedUser) => selectedUser.id === user.id),
    );

    // otherwise, render the search results
    return (
      <>
        <div className="form-search-results">
          {unselectedUsers.map((user) => (
            <div key={user.id} className="form-search-result-item">
              <input
                type="checkbox"
                checked={selectedUsers.some(
                  (selectedUser) => selectedUser.id === user.id,
                )}
                onChange={(e) => handleUserSelection(user, e)}
              />
              <OtherUserProfileButton user={user} size="1.8rem" />
              <h4>{user.username}</h4>
            </div>
          ))}
          <div>
            {isLoadingSearchResults ? (
              <div style={{ height: "2.2rem", color: "var(--primary-color)" }}>
                <LoadingIndicatorBars />
              </div>
            ) : searchResultsNextPageUrl ? (
              <div
                className="form-search-button"
                onClick={handleLoadMoreResults}
              >
                <h4>Click to load more users...</h4>
              </div>
            ) : (
              <div>
                <div className="form-search-button" onClick={handleInviteUser}>
                  <h3>Invite New User</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  // render the search field
  return (
    <div className="form-search-container">
      <div className="form-search-bar">
        <i className="bi bi-search form-search-icon"></i>
        <input
          type="text"
          className="form-search-input"
          placeholder="Search for a user"
          disabled={isLoading}
          value={searchValue}
          onChange={handleSearchValue}
        />
      </div>
      <SelectedResults />
      <SearchResults />
    </div>
  );
};

export default SearchField;

// src/components/SideBar/SideBarPostsList.jsx

import React from "react";

function SidebarPostsListLoading() {
  // TODO: use effect if there is an active project id to get the collective posts

  const cardStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: ".5rem",
    gap: ".5rem",
  };

  const cardImageStyle = {
    width: "2.5rem",
    height: "2.5rem",
    flexShrink: "0",
    borderRadius: "50%",
  };

  const cardDetailStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: ".5rem",
  };

  const buttonStyle = {
    height: "2rem",
    width: "2rem",
    flexShrink: "0",
    borderRadius: "50%",
  };

  return (
    <>
      <div style={cardStyle}>
        <div style={cardImageStyle} className="loading-component"></div>
        <div style={cardDetailStyle}>
          <div
            className="loading-component"
            style={{ height: ".8rem", borderRadius: "1rem", width: "80%" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: ".8rem", borderRadius: "1rem", width: "40%" }}
          ></div>
        </div>
        <div style={buttonStyle} className="loading-component"></div>
      </div>
    </>
  );
}
export default SidebarPostsListLoading;

// src/components/SideBar/SideBarLoading.jsx

import React from "react";
import SidebarPostsListLoading from "./SideBarPostsList/SideBarPostsListLoading";

// STYLES
const collectiveHeaderStyle = {
  display: "flex",
  height: "auto",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: ".5rem",
  padding: ".5rem",
};

const collectiveDetailStyle = {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  gap: ".5rem",
};

const collectiveImageStyle = {
  width: "3rem",
  height: "3rem",
  borderRadius: ".5rem",
  flexShrink: "0",
};

const accordionContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: ".5rem",
};

function SideBarLoading() {
  return (
    <>
      <div style={collectiveHeaderStyle}>
        <div style={collectiveImageStyle} className="loading-component"></div>
        <div style={collectiveDetailStyle}>
          <div
            className="loading-component"
            style={{ height: "1.2rem", borderRadius: "1rem", width: "90%" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: ".8rem", borderRadius: "1rem", width: "40%" }}
          ></div>
        </div>
      </div>
      <div style={accordionContainerStyle}>
        <div
          style={{ height: "1.5rem", borderRadius: "1rem" }}
          className="loading-component"
        ></div>
      </div>
      <div style={accordionContainerStyle}>
        <div
          style={{ height: "1.5rem", borderRadius: "1rem" }}
          className="loading-component"
        ></div>
      </div>
      <div>
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
        <SidebarPostsListLoading />
      </div>
    </>
  );
}

export default SideBarLoading;

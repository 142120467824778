import React from "react";
import BaseModal from "./BaseModal.jsx";
import { useStore } from "@nanostores/react";
import { editCollectiveMembersModalOpenStore } from "../../stores/modalStore.js";
import EditCollectiveMembersForm from "../Forms/EditCollectiveMembersForm.jsx";

function EditCollectiveMembersModal() {
  const showModal = useStore(editCollectiveMembersModalOpenStore);

  return (
    <>
      <BaseModal
        modalTitle="Edit Collective Members"
        showModal={showModal}
        isLoading={false}
      >
        <div className="modal-form">
          <EditCollectiveMembersForm />
        </div>
      </BaseModal>
    </>
  );
}

export default EditCollectiveMembersModal;

//src/components/DetailArea/CollectiveDetail/CollectiveDetail.jsx

import React, { useState, useEffect } from "react";
import { CardSection } from "../../Utils/CustomCard";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  collectiveUserRoleStore,
} from "../../../stores/collectiveDetailStore";
import { getCollectiveDetail } from "../../../services/Collectives/getCollectiveDetail";
import HeaderCard from "./Components/HeaderCard";
import ProjectListCard from "./Components/ProjectListCard";
import MembersCard from "./Components/MembersCard";
import PendingInvitesCard from "./Components/PendingInvitesCard";
import { userIsAdminOwnerOrMember } from "../../Utils/permissionsUtils";
import { editCollectiveModalOpenStore } from "../../../stores/modalStore";

function CollectiveDetail() {
  // STORE VALUES
  const activeCollectiveId = useStore(collectiveIdStore);
  const currentUserRole = useStore(collectiveUserRoleStore);
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);

  // STATE VALUES
  const [isLoading, setIsLoading] = useState(true);
  const [collectiveDetails, setCollectiveDetails] = useState({});
  const title = collectiveDetails?.title;
  const image = collectiveDetails?.collective_image;
  const description = collectiveDetails?.description;
  const members = collectiveDetails?.members;
  const projectList = collectiveDetails?.project_list;
  const pendingInvites = collectiveDetails?.pending_invites;

  // API GET REQUESTS
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (activeCollectiveId) {
        setIsLoading(true);
        try {
          const collectiveDetails =
            await getCollectiveDetail(activeCollectiveId);
          setCollectiveDetails(collectiveDetails);
          setIsLoading(false);
        } catch (err) {}
      }
    };

    fetchUserDetails();
  }, [activeCollectiveId]);

  const handleEditCollectiveClick = () => {
    editCollectiveModalOpenStore.set(true);
  };

  return (
    <div className="detail-area">
      <CardSection>
        <HeaderCard
          headerTitle={title}
          headerSubtitle={"Collective"}
          descriptionTitle={"Collective Description"}
          description={description}
          image={image}
          isLoading={isLoading}
          isEditable={userCanEdit}
          onEditClick={handleEditCollectiveClick}
        />
        <ProjectListCard
          title={"Collective Projects"}
          projectList={projectList}
          collectiveId={activeCollectiveId}
          isLoading={isLoading}
          isHidden={!userCanEdit && !projectList?.length > 0}
          isEditable={userCanEdit}
        />
        <MembersCard
          title={"Collective Members"}
          members={members}
          isLoading={isLoading}
          isEditable={userCanEdit}
        />
        <PendingInvitesCard
          title={"Pending Collective Invitations"}
          pendingInvites={pendingInvites}
          isLoading={isLoading}
          isEditable={userCanEdit}
          isHidden={!userCanEdit}
        />
      </CardSection>
    </div>
  );
}

export default CollectiveDetail;

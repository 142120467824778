//src/components/DetailArea/ProjectDetail/ProjectDetail.jsx

import React, { useState, useEffect } from "react";
import { CardSection } from "../../Utils/CustomCard";
import { useStore } from "@nanostores/react";
import {
  collectiveIdStore,
  collectiveTitleStore,
  collectiveUserRoleStore,
  projectIdStore,
} from "../../../stores/collectiveDetailStore";
import { getCollectiveDetail } from "../../../services/Collectives/getCollectiveDetail";
import HeaderCard from "../CollectiveDetail/Components/HeaderCard";
import ProjectListCard from "../CollectiveDetail/Components/ProjectListCard";
import MembersCard from "../CollectiveDetail/Components/MembersCard";
import PendingInvitesCard from "../CollectiveDetail/Components/PendingInvitesCard";
import { userIsAdminOwnerOrMember } from "../../Utils/permissionsUtils";
import { editProjectModalOpenStore } from "../../../stores/modalStore";

function ProjectDetail() {
  // STORE VALUES
  const activeCollectiveId = useStore(collectiveIdStore);
  const activeCollectiveTitle = useStore(collectiveTitleStore);
  const activeProjectId = useStore(projectIdStore);
  const currentUserRole = useStore(collectiveUserRoleStore);
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);
  // STATE VALUES
  const [isLoading, setIsLoading] = useState(true);
  const [collectiveDetails, setCollectiveDetails] = useState({});
  const pendingInvites = collectiveDetails?.pending_invites;

  // get the collective details from the response
  const members = collectiveDetails?.members;
  const projectList = collectiveDetails?.project_list;

  // get the project details from the response
  const projectDetails = collectiveDetails?.project;
  const title = projectDetails?.title;
  const image = projectDetails?.project_image;
  const description = projectDetails?.description;

  // API GET REQUESTS
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (activeCollectiveId) {
        setIsLoading(true);
        try {
          const collectiveDetails = await getCollectiveDetail(
            activeCollectiveId,
            activeProjectId,
          );
          setCollectiveDetails(collectiveDetails);
          setIsLoading(false);
        } catch (err) {}
      }
    };

    fetchUserDetails();
  }, [activeCollectiveId, activeProjectId]);

  const handleProjectClick = () => {
    editProjectModalOpenStore.set(true);
  };

  return (
    <div className="detail-area">
      <CardSection>
        <HeaderCard
          headerTitle={title}
          headerSubtitle={"Project by " + activeCollectiveTitle}
          descriptionTitle={"Project Description"}
          description={description}
          image={image}
          isLoading={isLoading}
          isEditable={userCanEdit}
          onEditClick={handleProjectClick}
        />
        <ProjectListCard
          title={"Collective Projects"}
          projectList={projectList}
          collectiveId={activeCollectiveId}
          isLoading={isLoading}
          isHidden={!userCanEdit && !projectList?.length > 0}
          isEditable={userCanEdit}
        />
        <MembersCard
          title={"Collective Members"}
          members={members}
          isLoading={isLoading}
          isEditable={userCanEdit}
        />
        <PendingInvitesCard
          title={"Pending Collective Invitations"}
          pendingInvites={pendingInvites}
          isLoading={isLoading}
          isEditable={userCanEdit}
          isHidden={!userCanEdit}
        />
      </CardSection>
    </div>
  );
}

export default ProjectDetail;

// src/components/SideBar/SideBarPostCard.jsx

import React from "react";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton.jsx";
import AddParentPostButton from "../../Buttons/AddParentPostButton.jsx";
import { useStore } from "@nanostores/react";
import { currentUserStore } from "../../../stores/currentUserStore.js";
import { createParentPostModalOpenStore } from "../../../stores/modalStore";

function SideBarNewPostCard() {
  const user = useStore(currentUserStore);

  const handleNewPostClick = () => {
    createParentPostModalOpenStore.set(true);
  };

  return (
    <div className="sidebar-post-card" onClick={handleNewPostClick}>
      <OtherUserProfileButton user={user} size={"2.2rem"} />
      <div className="sidebar-post-detail">
        <div className="sidebar-post-info">
          <div className="sidebar-post-title">
            <p className="text-overflow-trim text-break text-accented">
              Create Post
            </p>
          </div>
        </div>
      </div>
      <AddParentPostButton />
    </div>
  );
}

export default SideBarNewPostCard;

// src/services/Posts/addPost.js

import axiosInstance from "../Utils/axiosInstance";

const addPost = async (
  parentPostId,
  collectiveId,
  title,
  comment,
  files,
  richTextJson,
  mentionedUserIds,
  mentionedPostIds,
  trackTimestamp,
  relatedPostContentId,
) => {
  // Define states
  let successMessage = "";
  let warningMessage = "";
  let dangerMessage = "";
  let newPostId = null;

  // call the API
  try {
    // Create a new FormData object
    const formData = new FormData();

    formData.append("collective", parseInt(collectiveId, 10));
    formData.append("title", title);
    formData.append("comments[0]comment", comment);

    // apppend conditional fields
    if (files !== undefined) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    // append parent post id if it exists
    if (parentPostId) {
      formData.append("parent", parseInt(parentPostId, 10));
    }

    // append track timestamp if it exists
    if (trackTimestamp !== undefined) {
      formData.append("comments[0]track_timestamp", trackTimestamp);
    }

    // append related post content id if it exists
    if (relatedPostContentId !== undefined) {
      formData.append("comments[0]related_post_contents", relatedPostContentId);
    }

    // append rich text json if it exists
    if (richTextJson !== undefined) {
      formData.append("comments[0]rich_text_comment", richTextJson);
    }

    // append mentioned user ids if they exist
    if (mentionedUserIds !== undefined && mentionedUserIds.length > 0) {
      mentionedUserIds.forEach((userId, index) => {
        formData.append(`comments[0]mentioned_users[${index}]`, userId);
      });
    }

    // append mentioned post ids if they exist
    if (mentionedPostIds !== undefined && mentionedPostIds.length > 0) {
      mentionedPostIds.forEach((postId, index) => {
        formData.append(`comments[0]mentioned_posts[${index}]`, postId);
      });
    }

    const response = await axiosInstance.post("/api/posts/create/", formData);
    successMessage = "Post created successfully";
    newPostId = response.data.id;
  } catch (error) {
    if (error.response.data.detail) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      dangerMessage = error.response.data.detail;
    } else if (error.request) {
      // The request was made but no response was received
      dangerMessage = "Please check your internet connection and try again.";
    } else {
      // Something happened in setting up the request that triggered an Error
      dangerMessage = "Please check your internet connection and try again.";
    }
  }
  return { successMessage, warningMessage, dangerMessage, newPostId };
};

export { addPost };

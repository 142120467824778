import React from "react";
import NotificationObject from "./NotificationObject";
import OptionsDropdown from "./OptionsDropdown";

const NotificationsContainer = ({ notificationsData, isLoading }) => {
  const sortedNotifications = [...notificationsData].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );

  if (isLoading) {
    return (
      <div className="notifications-container">
        <div>
          <h1>Notifications</h1>
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
          <NotificationObject isLoading={isLoading} />
        </div>
      </div>
    );
  }

  return (
    <div className="notifications-container">
      <div>
        <h1>Notifications</h1>
      </div>
      <OptionsDropdown />
      {sortedNotifications.map((notification, index) => (
        <NotificationObject notification={notification} key={index} />
      ))}
    </div>
  );
};

export default NotificationsContainer;

import React from "react";
import BaseModal from "./BaseModal.jsx";
import { useStore } from "@nanostores/react";
import { editUserProfileModalOpenStore } from "../../stores/modalStore.js";
import EditProfileForm from "../Forms/EditProfileForm.jsx";

function EditProfileModal() {
  const showModal = useStore(editUserProfileModalOpenStore);

  return (
    <>
      <BaseModal
        modalTitle="Edit My Profile"
        showModal={showModal}
        isLoading={false}
      >
        <div className="modal-form">
          <EditProfileForm />
        </div>
      </BaseModal>
    </>
  );
}

export default EditProfileModal;

//src/pages/NotificationsPage.jsx

import React, { useEffect, useState } from "react";
import NotificationsContainer from "../components/Notifications/NotificationsContainer";
import { listNotifications } from "../services/Notifications/listNotifications";

const NotificationsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchNotifications = async () => {
      try {
        const notificationsData = await listNotifications();
        setNotificationsData(notificationsData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="main-layout">
      <div className="notifications-page-layout">
        <NotificationsContainer
          notificationsData={notificationsData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default NotificationsPage;

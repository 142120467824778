// src/components/Utils/handleDownload.jsx

import { downloadTrack } from "../../services/Tracks/downloadTrack";
import { downloadAttachment } from "../../services/Attachments/downloadAttachment";

export const handleDownload = async ({ isTrack, fileId, fileName }) => {
  try {
    const downloadFunction = isTrack ? downloadTrack : downloadAttachment;
    const blob = await downloadFunction(fileId);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  } finally {
  }
};

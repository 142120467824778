import React from "react";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton";
import { markNotificationAsRead } from "../../services/Notifications/markNotifications.js";
import UnreadPostIcon from "../Icons/UnreadPostIcon.jsx";
import { formatPostTimestamp } from "../Utils/formatTime.js";
import { usePostNavigation } from "../../hooks/navigationHooks.js";

const NotificationObject = ({ notification, isLoading }) => {
  const navigateToPost = usePostNavigation();
  const message = notification?.message;
  const subMessage = notification?.sub_message;
  const notificationDate = formatPostTimestamp(notification?.created_at);
  const notificationReadDate = notification?.read_date;
  const notificationIsRead = notificationReadDate !== null;
  const initiator = notification?.initiator;
  const collectiveId = notification?.collective;
  const parentPostId = notification?.parent_post_id;
  const childPostId = notification?.post;

  const handleNotificationClick = () => {
    if (!notificationIsRead) {
      markNotificationAsRead(notification.id);
    }
    navigateToPost(collectiveId, parentPostId, childPostId);
  };

  if (isLoading) {
    return (
      <div
        className="notification-object-loading"
        style={{ marginTop: ".5rem" }}
      >
        <OtherUserProfileButton size={"3rem"} />
        <div
          className="notification-detail"
          style={{ display: "flex", gap: ".5rem" }}
        >
          <div
            className="loading-component"
            style={{ height: ".8rem", width: "5rem", borderRadius: ".5rem" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: "1rem", width: "50%", borderRadius: ".5rem" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: ".8rem", width: "80%", borderRadius: ".5rem" }}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div className="notification-object" onClick={handleNotificationClick}>
      <div>
        <OtherUserProfileButton user={initiator} size={"3rem"} />
      </div>
      <div className="notification-detail">
        <p className="sm text-muted">
          <span>{notificationDate}</span>
        </p>
        <h4>{message}</h4>
        <p className="text-muted">"{subMessage}"</p>
      </div>
      {!notificationIsRead && (
        <div className="notification-unread-icon">
          <UnreadPostIcon />
        </div>
      )}
    </div>
  );
};

export default NotificationObject;

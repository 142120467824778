// src/components/AudioPlayer/WebAudioManager.js

import {
  playPreviousTrack,
  playNextTrack,
} from "../../stores/activeTrackStore";

class WebAudioManager {
  constructor() {
    this.audio = new Audio();
    // Set the onended event to play the next track from the store
    this.audio.onended = () => playNextTrack();
  }

  setTrack(url, trackDetails) {
    if (this.audio.src !== url) {
      this.audio.src = url;
      // media session actions for native media controls
      this.updateMediaSession(trackDetails);
    }
  }

  // Update the media session metadata with the track details
  updateMediaSession(trackDetails) {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: trackDetails.title,
        artist: trackDetails.username,
        artwork: [
          {
            src: trackDetails.profile_image,
            sizes: "96x96",
            type: "image/png",
          },
          {
            src: trackDetails.profile_image,
            sizes: "128x128",
            type: "image/png",
          },
          {
            src: trackDetails.profile_image,
            sizes: "192x192",
            type: "image/png",
          },
          {
            src: trackDetails.profile_image,
            sizes: "256x256",
            type: "image/png",
          },
          {
            src: trackDetails.profile_image,
            sizes: "384x384",
            type: "image/png",
          },
          {
            src: trackDetails.profile_image,
            sizes: "512x512",
            type: "image/png",
          },
        ],
      });

      // Add media session action handlers
      navigator.mediaSession.setActionHandler("play", this.play.bind(this));
      navigator.mediaSession.setActionHandler("pause", this.pause.bind(this));
      navigator.mediaSession.setActionHandler("seekto", (event) => {
        if (!this.audio.duration) return;
        this.audio.currentTime = event.seekTime;
        this.play();
      });
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        playPreviousTrack();
      });
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        playNextTrack();
      });
    }
  }

  play(callback) {
    const promise = this.audio.play();
    if (callback && typeof callback === "function") callback();
    return promise;
  }

  pause(callback) {
    this.audio.pause();
    if (callback && typeof callback === "function") callback();
  }

  getDuration() {
    return this.audio.duration;
  }

  getCurrentTime() {
    return this.audio.currentTime;
  }

  seekTo(time) {
    this.audio.currentTime = time;
  }

  onEnded(callback) {
    this.audio.onended = () => {
      if (callback) callback();
      playNextTrack();
    };
  }
}

// Export a single instance to be used application-wide
export const webAudioManager = new WebAudioManager();
